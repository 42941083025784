# # ImproveResource
import filesize from 'filesize'
import ComponentHub from "core/componentsHub.coffee"
import {apiGetResource, apiGetResourceContent, apiGetRevisionHistory, apiGetRevisionContent, apiGetResourceMetadata,
        apiGetRevisionMetaData, apiGetRootDirWithMeta, apiGetResourceByPath, apiGetSubTree,apiGetSubTreeWithMeta, apiCopyResource, apiMoveResource,
        apiDeleteResource, apiNewFolder, apiResourceUpdate, apiResourceLock, apiNewReview} from 'apis/improveREST/rawImprove.coffee'
import {resourceTypes} from './resourceTypes.coffee'
import {MetaData} from './MetaData.coffee'
import {exportFile} from 'quasar'
Buffer = require('buffer')
mime = require('mime')
#mime.define({'text/python': ['py', 'pyw']})
#mime.define({'text/r-script': ['r','rmd']})
#mime.define({'text/nonmem': ['ctl','fdata','nmt','mod','trn','dat']})
#mime.define({'text/step': ['grd','phi','shk','shm','cor','coi','cov']})




export class ImproveResource
  #region Getters
  ###* virtual Field (Getter/Setter)
    * gets or sets BrowserMode via tabPinned
    * @name ImproveResource#tabPinned
    * @property {boolean} tabPinned
  ###
  Object.defineProperty this::, "tabPinned",
    get: ->
      return this._tabPinned
    enumerable: true
    configurable: true
    set: (val) ->
      this._tabPinned = val

  ###* virtual Field (Getter) returns the actionButtons
    * @name ImproveResource#actionButtons
    * @type {FileActionButtons[]}
    ###
  Object.defineProperty this::, "actionButtons",
    get: ->
      if this._editor?
        return this.createToolBarActions().concat(this._editor.createToolBarActions())
      return this.createToolBarActions()
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name ImproveResource#currentEditor
    * @type {object|null} currentEditor
    ###
  Object.defineProperty this::, "currentEditor",
    get: -> this._editor
    enumerable: true
    configurable: true
    set: (val) ->
      this._editor = val

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name ImproveResource#currentEditorIsMonaco
    * @type {boolean} currentEditorIsMonaco
    ###
  Object.defineProperty this::, "currentEditorIsMonaco",
    get: ->
      if this._editor?
        if this._editor.editorType is 'monaco'
          return true
      return false
    enumerable: true
    configurable: true
  #endregion Getters

  ###*
    virtual Field (Getter) returns the name shown in the content tab
    * @name ImproveResource#displayName
    * @type {string}
    ###
  Object.defineProperty this::, "displayName",
    get: ->
      if this.lastModifiedOn?
        return this.name
      return ''
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the lastModified Unix timestamp into a locale string
    * @name ImproveResource#lastModifiedFmt
    * @type {string}
    ###
  Object.defineProperty this::, "lastModifiedFmt",
    get: ->
      if this.lastModifiedOn?
        return new Date(parseInt(this.lastModifiedOn)).toLocaleString()
      return ''
    enumerable: true
    configurable: true

  ###*
    virtual Field (Getter) returns the lastModified Unix timestamp
    * @name ImproveResource#lastModified
    * @type {number}
    ###
  Object.defineProperty this::, "lastModified",
    get: ->
      if this.lastModifiedOn?
        return parseInt(this.lastModifiedOn,10)
      return 0
    enumerable: true
    configurable: true

  ###*
    virtual Field (Getter) returns the created Unix timestamp
    * @name ImproveResource#created
    * @type {number}
    ###
  Object.defineProperty this::, "created",
    get: ->
      if this.createdAt?
        return parseInt(this.createdAt,10)
      return 0
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the createdAt Unix timestamp into a locale string
    * @name ImproveResource#createdAtFmt
    * @type {string}
    ###
  Object.defineProperty this::, "createdAtFmt",
    get: ->
      if this.createdAt?
        return new Date(parseInt(this.createdAt )).toLocaleString()
      return ''
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the locked state
    * @name ImproveResource#isLocked
    * @type {boolean}
    ###
  Object.defineProperty this::, "isLocked",
    get: ->
      if this.lockedAt? and this.lockedAt.length
        return true
      return false
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the lockedAt Unix timestamp into a locale string
    * @name ImproveResource#lockedAtFmt
    * @type {string}
    ###
  Object.defineProperty this::, "lockedAtFmt",
    get: ->
      if this.lockedAt? and this.lockedAt.length
        try
          return new Date(parseInt(this.lockedAt )).toLocaleString()
        catch e
          return ''
      return ''
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the formatted pretty fileSize
    * filesize(500);                        // "500 B"
    * filesize(500, {bits: true});          // "4 kbit"
    * filesize(265318, {base: 2});          // "259.1 KiB"
    * filesize(265318);                     // "265.32 kB"
    * filesize(265318, {round: 0});         // "265 kB"
    * filesize(265318, {output: "array"});  // [265.32, "kB"]
    * filesize(265318, {output: "object"}); // {value: 265.32, symbol: "kB", exponent: 1, unit: "kB"}
    * filesize(1, {symbols: {B: "Б"}});     // "1 Б"
    * filesize(1024);                       // "1.02 kB"
    * filesize(1024, {exponent: 0});        // "1024 B"
    * filesize(1024, {output: "exponent"}); // 1
    * filesize(265318, {standard: "jedec"});  // "259.1 KB"
    * filesize(265318, {base: 2, fullform: true}); // "259.1 kibibytes"
    * filesize(12, {fullform: true, fullforms: ["байтов"]});  // "12 байтов"
    * filesize(265318, {separator: ","});   // "265,32 kB"
    * filesize(265318, {locale: "de"});   // "265,32 kB"
    * @name ImproveResource#fileSizeFmt
    * @type {string} pretty fileSize
    ###
  Object.defineProperty this::, "fileSizeFmt",
    get: ->
      if this.fileSize?
        return filesize(this.fileSize, {round: 2})
      return ''
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns  the icon for review status
    * @name ImproveResource#statusColumn
    * @type {string} string
    ###
  Object.defineProperty this::, "statusColumn",
    get: -> this.getStatusIcon()
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the mimeType
    * @name ImproveResource#mimeType
    * @type {string} string  mimeType
    ###
  Object.defineProperty this::, "mimeType",
    get: -> this.getMimeType()
    enumerable: true
    configurable: true

  ###*
    * virtual Field (Getter) returns the treeId*
    * The treeId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtul space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name ImproveResource#treeId
    * @type {string} string Id
    ###
  Object.defineProperty this::, "treeId",
    get: -> this.resourceId
    enumerable: true
    configurable: true


  ###*
    * virtual Field (Getter) returns the treeParentId
    * The treeParentId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name ImproveResource#treeParentId
    * @type {string} string Id
    ###
  Object.defineProperty this::, "treeParentId",
    get: ->
      if this.hasOwnProperty("_treeParentId")
        return this._treeParentId
      else
        return this.parentId
    enumerable: true
    configurable: true


  ###*
    * virtual Field (Getter) returns the virtualPath*
    * The virtualPath may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly.
    * In case a workspace or resource was openend,
    * which is NOT "/", not the root resource, it might be, that the user wants to combine different branches from different real tree levels
    * or different resources from different treelevels or both mixed up within one tree
    * in that case the real objects have different parents and different path lengths, but they all should appear
    * at the same level in the FileSystemTree.
    * To accomplish that, we assing a _vpath property only to the items in the (virtual) root which is always
    * / + name.  This happens in connectionStatus.fetchInToVirtualRoot
    *  All child objects of those return for virtualPath the _vpath if they own this property, if not, the one of their
    * parent + their name . So we have a virtual path to determine the inset levels in the treetable, This is what here happens.
    @name ImproveResource#virtualPath
    @type {string} string path
    ###
  Object.defineProperty this::, "virtualPath",
    get: ->
      if this.hasOwnProperty('_vpath')
        return this._vpath
      else
        return "#{this.parent.virtualPath}/#{this.name}"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the parent
    * @name ImproveResource#parentPath
    * @type {ImproveResource} parent
    ###
  Object.defineProperty this::, "parent",
    get: ->
      return this._parent
    set: (val) ->
      this._parent = val
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#parentPath
    * @type {string} parentPath
    ###
  Object.defineProperty this::, "parentPath",
    get: ->
      splitted = @path.split("/")
      return "/" + splitted[1..-2].join("/")
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the resourceId
    * A virtual getter to keep compatibility with FileSystemFiles
    * @name ImproveResource#ino
    * @type {string} ino
    ###
  Object.defineProperty this::, "ino",
    get: ->
      return this.resourceId
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the filePath
    * sometimes api functions still return the path without the filename
    * this property ensures, the path inclusive file name is returned
    * @name ImproveResource#filePath
    * @type {string}
    ###
  Object.defineProperty this::, "filePath",
    get: ->
      if this.path.endsWith(this.name)
        return this.path
      if this.path.endsWith("/")
        return "#{this.path}#{this.name}"
      return "#{this.path}/#{this.name}"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#cachedPath
    * @type {string}
    ###
  Object.defineProperty this::, "cachedPath",
    get: ->
      return this.path
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#size
    * @type {string}
    ###
  Object.defineProperty this::, "size",
    get: ->
      return this.fileSize
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#isDir
    * @type {string}
    ###
  Object.defineProperty this::, "isDir",
    get: ->
      return this.isFolderish
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#kind
    * @type {string}
    ###
  Object.defineProperty this::, "kind",
    get: ->
      if this.isForlderish
        return "directory"
      return "file"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ImproveResource#renderMode
    * @type {boolean}
    ###
  Object.defineProperty this::, "renderMode",
    get: ->
      if ComponentHub.layoutTree.activePanel.tabs[this.resourceId]?
        return ComponentHub.layoutTree.activePanel.tabs[this.resourceId].renderMode
      return false
    enumerable: true
    configurable: true

  ###
    Base Class representing a resource
    @constructor
    @param {object} rawData as returned from REST API
    ###
  constructor:(rawData)->
    ###* - true if the file resides within the workspace / tree
      * @type {boolean}
      ###
    this.isSettingsFile = false
    ###* - true if the file resides within the workspace / tree
      * @type {boolean}
      ###
    this.external = false
    ###* - Assign our name for easy checks
      * @type {string}
      ###
    this.className = this.constructor.name
    ###* - Our relative path, dummy just kept fpor ts compatibility
      * @type {string | null}
      ###
    this._path = ""  # @_path is the cached path, use with care !
    ###* - The raw file object obtained from FSW
      * @type {object}
      ###
    this.blob = {}
    ###* - Are we root ?
      * @type {boolean}
      ###
    this.isRoot =false
    ###* - True if we are an b64 encoded binary
      * @type {boolean}
      ###
    this.isB64 = false
    ###* - Our color in the FileBrowser
      * @type {string}
      ###

    # --------------------
    # - The iWebType tells us whether we are a plain resource or a subtype like review, step etc
    this.iWebType = 'ImproveResource'
    # - The WebContext tells us whether where we are located in the hierachy tree. Maybe plain resource or review etc
    this.iWebContext = 'ImproveResource'
    this.treeColor = 'primary'
    this.metadata = []
    this.treeIcon = 'mdi-file-document-outline'
    this.isFolderish = false
    this.parentId = '0'
    this.neverClose = false
    this.content = null
    this.revisionList =  []
    this.revisionLabel = this.getHumanReadableVersion()
    this.isEditable = true
    this.isRevision=false
    ## API Attributes
    this.type= ''
    this.resourceId= ''
    this.lockedById= ''
    this.lockedByName= ''
    this.lockedAt= '' #datetime
    this.comment= ''
    this.description= ''
    this.rationale= ''
    this.resourceVersionId= ''
    this.nodeType= ''
    this.characteristica = ''
    this.characteristicaIcon = ''
    this.name= ''
    this.deleted= false
    this.entityId= ''
    this.entityVersionId= ''
    this.revisionId= ''
    this.createdByName= ''
    this.createdById= ''
    this.createdAt= '' #datetime
    this.lastModifiedOn= '' #datetime
    this.lastModifiedById= ''
    this.lastModifiedByName= ''
    this.status= ''
    this.fileSize= 0
    this.hasChildren= false
    this.hasChildrenIncludingFiles= false
    this.path= ''
    this.targetId= ''
    this.targetRevisionId= ''
    this.targetNodeType= ''
    this.targetResourceName= ''
    this.targetFileSize= 0
    this.targetEntityId= ''
    this.targetEntityVersionId= ''
    this.targetLastModified= '' #datetime
    this.resourceCharacterId= ''
    this.url=''
    this.update rawData
    this.virtual = false
    if resourceTypes.hasOwnProperty this.nodeType
      this.treeIcon = resourceTypes[this.nodeType].icon
      this.isFolderish = if resourceTypes[this.nodeType].folderish then true else false
    this.getIconFromMetaData rawData
    this.actions=
      newFolder: @isFolderish
      save:false
      download: true
      upload: !@isFolderish
      lock: !@isFolderish
      unlock: @isLocked
      cancelLock: @isLocked
      rename: true
      copy: true
      copyFrom: @isFolderish
      move: true
      moveFrom: @isFolderish
      link: true
      linkFrom: @isFolderish
      delete: true
    ###* - Is our content prepared for an editor or display
      * @type {boolean}
      ###
    this.contentPrepared = false
    ###* When true (default) the tab keeps open when a new file is selected in tree, when false, the tab containing this file will be replaced
      * @type {boolean}
      ###
    this.tabPinned = true
    ###* - The editor we are currretnly loaded in
      * @type {object|null}
      ###
    this._editor = null
    #this.renderMode=false
    this._parent = null


  resetStates: ->
    #this.renderMode=false
    this.tabPinned = true

  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
   ###
  createToolBarActions: ->
    return [
      {
        name: "ToggleRenderView"
        highlight: this.renderMode
        disabled: false
        hidden: !(this.mimeType is 'text/markdown' or this.mimeType is 'text/html' )
        icon: if this.renderMode then 'o_article' else "o_code"
        tooltip: "Toggle Preview"
        click: () =>
          lt = ComponentHub.layoutTree
          tab = if this.iWebContext is "ImproveReview" then this.id else this.resourceId
          lt.activePanel.tabs[tab].renderMode = !lt.activePanel.tabs[tab].renderMode

      },
      {
        name: "Download"
        disabled: false
        highlight:false
        hidden: this.iWebType is 'ImproveReview'
        icon: "o_cloud_download"
        tooltip: "Download to local Disk"
        click: () => this.download()

      },
      {
        name: "Upload"
        disabled: this.isFolderish
        highlight:false
        hidden: this.iWebContext is 'ImproveReview'
        icon: "o_cloud_upload"
        tooltip: "Upload a new version"
        click: () =>

      },
      {
        name: "Lock"
        disabled: this.isFolderish
        highlight: false
        hidden: this.isLocked or this.iWebContext is 'ImproveReview'
        icon: "mdi-lock-outline"
        tooltip: "Lock Resource"
        click: () => this.lock(true)

      },
      {
        name: "Unlock"
        disabled: this.isFolderish
        highlight: this.isLocked
        hidden: !this.isLocked
        icon: "mdi-lock-open-outline"
        tooltip: "Unlock resource"
        click: () => this.lock(false)

      },
      {
        name: "Cancel Lock"
        disabled: false
        highlight:false
        hidden: true
        icon: "mdi-lock-off-outline"
        tooltip: "Cancel Lock"
        click: () =>

      },
      {
        name: "Rename"
        disabled: false
        highlight:false
        hidden: this.iWebContext is 'ImproveReview'
        icon: "drive_file_rename_outline"
        tooltip: "Rename"
        click: () =>

      },
      {
        name: "Delete"
        disabled: this.isLocked
        highlight:false
        hidden: this.iWebContext is 'ImproveReview'
        icon: "delete_outline"
        tooltip: "Delete"
        click: () => this.delete()

      }
    ]


  download: ->
    if ComponentHub.isDesktop
      if this.isFolderish
        if ComponentHub.activeWorkspace.hasOwnProperty("localPath")
          if ComponentHub.activeWorkspace.localPath isnt ''
            lPath = ComponentHub.activeWorkspace.localPath
            if ComponentHub.FS.osExists lPath
              kids = ComponentHub.FileSystemTree.getAllChildren this.resourceId
              foundFiles=false
              for kid in kids
                if not kid.isFolderish and ComponentHub.FileSystemTree.isRowVisible kid
                  try
                    foundFiles=true
                    content = await kid.getRawFileContents()
                    storePath = ComponentHub.FS.joinSave lPath,kid.filePath
                    ComponentHub.FS.osWriteFile(storePath,content,null)
                  catch error
                    console.warn "File could not be retrieved",kid.filePath,error
              if not foundFiles
                await ComponentHub.PageIndex.alert "Download", "Only files which are opened in the explorer tree will be downloaded. Open the tree up to the level of files you want to download."
                return
              else
                await ComponentHub.PageIndex.alert "Download", "Download succeeded to #{lPath}"
                return
            else
              await ComponentHub.PageIndex.alert "Download", "The local downloadpath, stored in workspace settings does not exist. Please check before downloading folders"
              return
          else
            await ComponentHub.PageIndex.alert "Download", "The local downloadpath, stored in workspace settings is empty. Please set before downloading folders"
            return
        else
          await ComponentHub.PageIndex.alert "Download", "No local downloadpath present in workspace settings. Maybe the workspace was imported this workspace from a webbrowser or from an older app version"
          return
      else
        content = await this.getRawFileContents()
        if ComponentHub.activeWorkspace.hasOwnProperty("localPath")
          if ComponentHub.activeWorkspace.localPath isnt ''
            lPath = ComponentHub.activeWorkspace.localPath
            if ComponentHub.FS.osExists lPath
              storePath = ComponentHub.FS.joinSave lPath,this.filePath
              ComponentHub.FS.osWriteFile(storePath,content,null)
              await ComponentHub.PageIndex.alert "Download", "Download succeeded to #{lPath}"
              return
        return exportFile(this.name,content)

    else
      if this.isFolderish
        if ComponentHub.activeServer.fileManager.rootDirHandle is null or ComponentHub.activePlugin.hideFrameWork
          #When framework is hidden, user can't change downloadpath, so we ask everytime
          ok = await ComponentHub.activeServer.fileManager.setNewHandle()
          if !ok
            return
        kids = ComponentHub.FileSystemTree.getAllChildren this.resourceId
        foundFiles=false
        for kid in kids
          if not kid.isFolderish  and ComponentHub.FileSystemTree.isRowVisible kid
            try
              foundFiles=true
              content = await kid.getRawFileContents()
              await ComponentHub.activeServer.fileManager.saveLocalFile(kid.filePath,content)
            catch error
              console.warn "File could not be retrieved",kid.filePath,error
        if not foundFiles
          await ComponentHub.PageIndex.alert "Download", "Only files which are opened in the explorer tree will be downloaded. Open the tree up to the level of files you want to download."
          return
        else
          msg = "Download succeeded to  #{kid.filePath} in this workspaces download path"
          if ComponentHub.activePlugin.hideFrameWork
            msg = "Download succeeded, report inputs files were downloaded in the selected target folder."
          await ComponentHub.PageIndex.alert "Download", msg
          return
      else
        content = await this.getRawFileContents()
        if ComponentHub.activeServer.fileManager.rootDirHandle is null or ComponentHub.activePlugin.hideFrameWork
          splname = this.name.split(".")
          splname.splice(splname.length-1,0,"v#{this.getVersionNumber().toString()}")
          newName=splname.join('.')
          status = await exportFile(newName,content)
          if status
            console.log "Download succeeded"
          else
            await ComponentHub.PageIndex.alert "Download", "The Webbrowser did not allow to download #{this.name}"
        else
          await ComponentHub.activeServer.fileManager.saveLocalFile(this.filePath,content)
          await ComponentHub.PageIndex.alert "Download", "Download of #{this.filePath} succeeded"

    return

  ###*
    * updates this instance with fresh data
    * update should be implemented on any derived class
    * when the resourcebrowser tree branch is refreshed, update is called
    * this keeps all informations of the object and just refreshes those where we get new data
    * @param {String} rawData as returned from REST API
    * @return {any}
    ###
  update: (rawData) ->
    this.lockedAt = ""
    this.lockedById = ""
    this.lockedByName = ""
    rawData && Object.assign this, rawData
    this.getIconFromMetaData rawData
    if this.hasOwnProperty("resourceCharacterId")
      if this.resourceCharacterId.trim().length
        if ComponentHub.activeServer.metaCharactersMapping.hasOwnProperty this.resourceCharacterId
          this.characteristica = ComponentHub.activeServer.metaCharactersMapping[this.resourceCharacterId].name
    this.reindex()
    return null


  getIconFromMetaData: (rawData) ->
    unless rawData?
      rawData = this
    if rawData.hasOwnProperty "metadata"
      if rawData.metadata.length
        icon = (md for md in rawData.metadata when md.descriptorName is "WebFolderIcon")
        if icon.length
          this.treeIcon= icon[0].textValue

  ###*
    * updates this instance and dependencies with fresh data
    * @param {String} rawData as returned from REST API
    * @return {any}
    ###
  deepUpdate: (rawData)->
    unless rawData?
      result = await ComponentHub.activeServer.doQuery apiGetResource(this.resourceId)
      rawData =  result.data
    await this.getRevisionHistory()
    this.isRevision=false
    await this.getMetadata()
    await this.getRawFileContents()
    @update(rawData)
    return
  ###
    * get the current date as string
    * @return {String} String The current Date as String
    ###
  getDate: () ->
    return new Date().toString()

  ###
    * returns the status icon of this resource for representation
    * @return {String} A String which represents icon and color
    ###
  getStatusIcon: () ->
    return 'horizontal_rule'

  ###
    * based on the name of the resource the mime-type will be returned
    * @return {String} String   like text/html, x-application/ms-word
    ###
  getMimeType: () ->
    if !this.isFolderish # nodeType isnt 'Folder'
      if this.name.indexOf(".") >= 0
        ###* @ts-ignore ###
        return mime.getType(this.name.toLowerCase())
      else
        #IM-5824 QuickFix
        if this.fileSize <= 1000000
          return 'text/other'
    return null


  ###
    * determines and returns the type of viewer, needed to display resource contents
    * @return {String}  String like: text, image, pdf
    ###
  getViewerType: () ->
    mimeType = this.getMimeType()
    if mimeType?
      mimeGroup = mimeType.split('/')[0].toLowerCase()
      switch mimeGroup
        when 'image', 'text'
          return  mimeGroup
        when 'application'
          type = mimeType.split('/')[1].toLowerCase()
          switch type
            when 'pdf'
              return type
            when 'javascript','json'
              return 'text'
            else
              return 'nonPreview'
        else
          return 'nonPreview'
    return 'nonPreview'

  ###
    * Fetches the file content of the resource for usage in different Viewewrs*
    * Do not use for downloads, the files would be unusable
    * @return {Arraybuffer|String} arraybuffer|string
    ###
  updateRawFileContents: () ->
    if not @isFolderish
      switch this.getViewerType()
        when 'text'
          responseType = 'json'
        #when 'image' then responseType = 'arraybuffer'
        #when 'pdf' then responseType = 'arraybuffer'
        else
          responseType = 'arraybuffer'

      result = await ComponentHub.activeServer.doQuery apiGetRevisionContent(this.resourceId,this.revisionId,responseType)
      this.content = result.data
    return this.content

  ###* Monaco cant wotrk with promises, so this is called at mount in editorng
   * @return {string | null}
  ###

  ###
    * Fetches the file content of the resource for usage in different Viewewrs*
    * Do not use for downloads, the files would be unusable
    * @return {Arraybuffer|String|Object}
    ###
  getRawFileContents: () ->
    unless this.content
      await this.updateRawFileContents()
    return this.content

  ###* Monaco cant wotrk with promises, so this is called at mount in editorng
   * @return {string | null}
  ###
  getInitContent: ->
    return await this.getRawFileContents()


  ###* Returns the Content of this File.
    *
    * this.content may be loaded already when the Filesystem was initialized, but this content is raw
    * When accessing this method the first time it etermines wheter the content is binary or not.
    * if it is binary and e.g an image it is converted to an inline data url so it may be displayed in image tags
    * the prepared content is cached in @content. when the file gets updated the cache is invalidated through
    * **this.contentPrepared**
    * @return {string} the usable content of the file
    ###
  getContent: ->
    return this.content

  ###
    * takes the raw filecontent and converts it in a format which is usable by the viewer
    * Mostly this is a base64 internal data-urL
    * @return {string} string, a simple string or the filecontent base64 data url
    ###
  getDisplayableFromContent: () ->
    unless this.content
      await this.getRawFileContents()
    if this.content?
      switch this.getViewerType()
        when 'image'
          reducer = (data, byte) => data + String.fromCharCode(byte)
          img =btoa(new Uint8Array(this.content).reduce(reducer, ''))
          return "data:#{this.mimeType};base64,#{img}"
        when 'text'
          return this.content
          this.reindex()
        when 'pdf'
          return this.content
        else
          if this.mimeType?
            type = this.mimeType.split('/')[1].toLowerCase()
            switch type
              when 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                return 'mdi-microsoft-word'
              when 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                return 'mdi-microsoft-excel'
              when 'vnd.ms-powerpoint', 'vnd.openxmlformats-officedocument.presentationml.presentation'
                return 'mdi-microsoft-powerpoint'
              else
                return 'mdi-file-document-outline'
          return 'mdi-file-document-outline'
    return null


  ###
    * returns the numerical version number from entity Version ID
    * @return {Number} Number, version
    ###
  getVersionNumber: () ->
    try
      return parseInt(this.entityVersionId.split('-')[-1..])
    catch e
      return 0


  ###
    * returns the version in form of "v 3 - 31.12.2021, 15:34:33" - from entity Version ID
    * @return {string} string, version
    ###
  getHumanReadableVersion: ()->
    try
      return "v #{this.entityVersionId.split('-')[-1..]} - #{this.lastModifiedFmt} - #{this.lastModifiedByName}"
    catch e
      return 0


  ###
    * fetches an array of resources with all our revisions
    * Every revision gets flagged as revision (isRevision=true)
    * and gets itself a reference to the list of all other revisions
    * (rev.revisionList = this.revisionList)
    * @return {Array} Array, revisions
    ###
  getRevisionHistory: () ->
    result = await ComponentHub.activeServer.doQuery apiGetRevisionHistory(this.resourceId)
    fetched = result.data
    this.revisionList = []
    i=0
    for revision in fetched
      rev = new ImproveResource(revision)
      if i>0
        rev.isEditable = false
        rev.isRevision=true
      i = i + 1
      rev.iWebContext = this.iWebContext
      this.revisionList.push(rev)
      rev.revisionList = this.revisionList


  ###
    * gets all subitems of this (folderish) resource
    * mostly used by ResourceBrowser
    * @return {Array of Objects} Array, resources
    ###
  onExpand: () ->
    rId = this.resourceId
    if rId.startsWith("v_")
      rId = rId[2..-1]
    await ComponentHub.activeServer.fetchData  apiGetSubTreeWithMeta(rId)


  ###
    * Loads also our relatedObjects like Metadata and revisions
    ###
  onDeepLoad: () ->
    if !this.virtual
      await this.getRevisionHistory()
      await this.getMetadata()
    return


  ###
    * fetches and fills our metadata from the server
    * @return {null} null
    ###
  getMetadata: () ->
    result = await ComponentHub.activeServer.doQuery apiGetResourceMetadata(this.resourceId)
    fetched = result.data
    this.metadata = []
    for record in fetched
      meta = new MetaData(record)
      this.metadata.push(meta)

  ###
    * getCopyMoveLinkTarget
    * used for drag and drop operations. When something is droppen on us, we have to return ourself if+we are folderish,
    * otherwise our parent
    * @return {ImproveResource} TargetResource
    ###
  getCMLTarget: () ->
    if @isFolderish
      return @
    else return @parent
  # API Implementations

  delete: () ->
    result = await ComponentHub.activeServer.doQuery apiDeleteResource(@resourceId)
    if result.status is 200
      parent = this.parentId
      ComponentHub.delTreeRecord this.resourceId
      await ComponentHub.refreshFolder parent
    return result.status is 200

  ### Copy / Move / link
    The copy and move operations are implemented in to ways
    - **copy to** means that this resource is copied to another resource
    - **copy from** means that another resource is copied into this resource

    This brings more flexibility in the usage of the resources you can use any of those methods depending on the current context of the use-cae

    The same logic is valid for move, link etc.
    ###

  ###
    * Copies this resource to another. The target  has to be folderish
    * @param {improveResource} target where should we copied to
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  copyTo: (target, commitMsg) ->
    if target.isFolderish
      if commitMsg?
        return @copy(@,target,commitMsg )
      else
        return @copy(@,target)
    else
      return false

  ###
    * Copies another resource into this one. This has to be folderish
    * @param {improveResource} source which should be copied
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  copyFrom: (source, commitMsg) ->
    if @isFolderish
      if commitMsg?
        return @copy(source,@,commitMsg )
      else
        return @copy(source,@)
    else
      return false

  ###
    * Copies one resource to another. The target  has to be folderish
    * @param {improveResource} source which should be copied
    * @param {improveResource} target where should we copied to
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  copy: (source,target, commitMsg) ->
    if target.hasOwnProperty('className')
      if target.className is "ReviewEntry" or target.className is 'ImproveReview'
        alert("Objects in Reviews may not be modified")
        return
    if !target.isFolderish
      return false
    if commitMsg?
      result = await ComponentHub.activeServer.doQuery apiCopyResource(source.resourceId,target.resourceId,source.name,commitMsg)
    else
      result = await ComponentHub.activeServer.doQuery apiCopyResource(source.resourceId,target.resourceId,source.name,null)
    return result.status is 200


  ###
    * Moves this resource to another. The target  has to be folderish
    * @param {improveResource} target where should we moved to
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  moveTo: (target, commitMsg) ->
    if target.isFolderish
      if commitMsg?
        return @move(@,target,commitMsg )
      else
        return @move(@,target)
    else
      return false

  ###
    * Moves another resource into this one. This has to be folderish
    * @param {improveResource} source which should be moved
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  moveFrom: (source, commitMsg) ->
    if @isFolderish
      if commitMsg?
        return @move(source,@,commitMsg )
      else
        return @move(source,@)
    else
      return false

  rename: (newName, commitMsg) ->
    result = await ComponentHub.activeServer.doQuery apiMoveResource(@resourceId,@parentId,newName, commitMsg)
    return result.status is 200


  ###
  Moves one resource to another. The target  has to be folderish
    * @param {improveResource} source which should be moved
    * @param {improveResource} target where should we moved to
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  move: (source,target, commitMsg) ->
    if source.hasOwnProperty('className')
      if source.className is "ReviewEntry"
        alert("Objects in Reviews may not be moved around")
        return  false
    if target.hasOwnProperty('className')
      if target.className is "ReviewEntry" or target.className is 'ImproveReview'
        alert("Objects in Reviews may not be modified")
        return
    if !target.isFolderish
      return false
    if commitMsg?
      result = await ComponentHub.activeServer.doQuery apiMoveResource(source.resourceId,target.resourceId,source.name,commitMsg)
    else
      result = await ComponentHub.activeServer.doQuery apiMoveResource(source.resourceId,target.resourceId,source.name,commitMsg)
    return result.status is 200

  ###
  Creates a new folderish item either within this resource if it is folderish or in this resources parent
    * @param {string} name the name of the new resource
    * @param {string} type the type Can be "Folder" (default),"Step", "Review" or "Analysis Tree"
    * @param {string} commitMsg any message
    * @return {boolean}  success
    ###
  newFolder: (name,type, commitMsg) ->
    if @isFolderish
      id = @resourceId
    else
      id = @parentId
    unless type?
      type='Folder'
    if type is "Review"
      return @newReview(name,commitMsg)

    if commitMsg?
      result = await ComponentHub.activeServer.doQuery apiNewFolder(id,name,type,commitMsg)
    else
      result = await ComponentHub.activeServer.doQuery apiNewFolder(id,name,type,null)
    if result.status >= 200 and result.status < 300
      newId = result.data.resourceId
      return newId
    else
      return false

  ###
    * Creates a new empty Review Item
    * @param {string} name the name of the new resource
    * @param {string} commitMsg any message
    * @return {boolean}  success
    ###
  newReview:(name,commitMsg) ->
    result = await ComponentHub.activeServer.doQuery apiNewReview name,@path,commitMsg
    if result.status >= 200 and result.status < 300
      newId = result.data.resourceId
      return newId
    else
      return false


  ###
    * updates our content at serverside
    * after that we fetch new metadata, revisions nd update ourself with data from server (response)
    * @param {string} content
    * @param {string} commitMsg Optional
    * @return {boolean} success
    ###
  updateContent: (content,commitMsg) ->
    result = await ComponentHub.activeServer.doQuery apiResourceUpdate(@resourceId,content,commitMsg)
    if result.status is 200
      await this.getRevisionHistory()
      this.isRevision=false
      await this.getMetadata()
      await this.getRawFileContents()
      @update(result.data)
      if ComponentHub.hasOwnProperty("#{this.resourceId}_revisionbar")
        ComponentHub["#{this.resourceId}_revisionbar"].onNewResource()
    return result.status is 200


  lock: (lock=true) ->
    result = await ComponentHub.activeServer.doQuery apiResourceLock(@resourceId,lock)
    if result.status is 200
      @update(result.data)
      if !result.data.hasOwnProperty('lockedAt')
        this.lockedAt = ""
        this.lockedById = ""
        this.lockedByName = ""
    return result.status is 200

  mayEditLock: ->
    if @isLocked
      return ComponentHub.activeServer.userdata.id is @lockedById
    return true

  ###* Saves the file back to FS with its current content
    *
    * **Important** Never save back binary files !!
    * @return {void}
   ###
  save: () ->
    if @content?
      this.updateContent this.content
      ComponentHub.storeActiveIndex()
      return

  ###*
   * @description Reindexes the file and put it into our Index
   * You have to recall this if you are dealing with special Filetypes which need special fields after super() in update()
  ###
  reindex: ->
    if !this.isFolderish and ComponentHub.hasOwnProperty("FileSystemTree")
      if ComponentHub.FileSystemTree.watcherReady or !ComponentHub.FileSystemTree.indexFilePresent
        @unindex()
        ComponentHub.indexer.add(this)
    return

  ###*
   * @description Removes us from search Index
  ###
  unindex: ->
    try
      res = ComponentHub.indexer.search(this.ino, { fields: ['id'] })
      if res.length
        ComponentHub.indexer.remove this
    catch e
      # never mind
    return

  ###* returns the directory we reside in
    *
    * @return {string}
    ###
  directory: ->
    splitted = @path.split("/")
    return "/" + splitted[1..-2].join("/")
