###*
  * Is responsible for hierarchical sorting of the tree component
  * @module components/FileSystemBrowser/treesorting
  ###

###* depth Tree sort sorts a deep hierarchical Tree levelwise
  *
  * parameters:
  *   1.) an array of objects like
  *   ```
  *   [{id: 1, name:"Foo", balance:100, parent : 0 },
  *     {id: 2, name:"Baz", balance:102, parent : 1 },
  *     {id: 3, name:"Bar", balance:302, parent : 1 },
  *    {id: 4, name:"Buz", balance:343, parent : 0 },
  *     {id: 5, name:"Zub", balance:534, parent : 4 },
  *     {id: 6, name:"Ubo", balance:999, parent : 4 },
  *   ]
  *   ```
  *   2.) a sort method like (here we are sorting by name)
  *
  *   ```
  *   asc = (a,b) ->
  *     if a.name is b.name
  *       return 0
  *     if a.name < b.name
  *       return -1
  *     return 1
  *   ```
  *
  *   3.) a rowKey in the example above this would be id
  *   4.) a parentKey in the example above this would be parent
  *   5.) a startParentKey in the example above this could be 0 for all
  *
  *   Even it returns the sorted array, be careful, since the given array is
  *   manipulated as well
  * @param {Array} arr  - The array to be sorted
  * @param {Function} cmp - the compare function
  * @param {string} rowKey - name of the unique id field
  * @param {string} parentKey - name of the parentKey Field
  * @param {string} startParentKey - the parent key the hierachy is starting
  * @return {Array}
  ###
export depthTreeSort = (arr, cmp, rowKey,parentKey, startParentKey ) ->
  i = 0
  # Returns an object, where each key is a node number, and its value
  # is an array of child nodes.
  makeTree = (arr) ->
    # first make a tree with all parents as keys containing their children
    tree = {}
    j = 0
    while j < arr.length
      if !tree[arr[j][parentKey]]
        tree[arr[j][parentKey]] = []
      tree[arr[j][parentKey]].push arr[j]
      j++
    tree

  # For each node in the tree, starting at the given id and proceeding
  # depth-first (pre-order), sort the child nodes based on cmp, and
  # call the callback with each child node.

  depthFirstTraversal = (tree, id, cmp, callback) ->
    children = tree[id]
    if children
      children.sort cmp
      k = 0
      while k < children.length
        callback children[k]
        depthFirstTraversal tree, children[k][rowKey], cmp, callback
        k++
    return

  # call it!
  depthFirstTraversal makeTree(arr), startParentKey, cmp, (node) ->
    arr[i++] = node
    return
  return arr


###*
  * # TableSort
  * Sorts the table levelwise. The sorting happens only within one hierarchy level
  * called by the table widget. This method takes care of hierarchy and expanded states of the tree
  * @param {Array} rows all rows to be sorted Array of ImproveResources
  * @param {string} sortBy he column to sort after
  * @param {boolean} descending true for descending, false for ascending
  * @return {Array} the sorted array
  ###
export tableSort = (rows, sortBy, descending, sortAlias) ->
  data = [rows...]
  # We first check of the sorting should be based on another field by using the sortAlias Map
  if sortBy
    if sortBy of sortAlias
      sortBy = sortAlias[sortBy]
  asc = (a, b) ->
    # our plain sorting algorithm
    # change any sort logic only here
    if a[sortBy] is b[sortBy]
      return 0
    if a[sortBy] < b[sortBy]
      return -1
    return 1

  desc = (a, b) ->
    # for descending just reverse parameters
    return asc(b, a)
  # if there is any sorting requested
  if sortBy
    if descending
      return data.sort(desc)
    else
      return data.sort(asc)
  return data
