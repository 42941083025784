###* This is called from FileSystemBrowser to instantiate automatically the correct FileClass for any new File
  *
  * arriving through FS or RESt or whatever
  * Do not forget to register your specialized file classes in the registeredFileClasses module
  * @module components/FileSystemBrowser/FileClasses
  ###
import {FileSystemFile} from "components/FileSystemBrowser/FileSystemFile.coffee"
import {registeredFileClasses} from "components/FileSystemBrowser/registeredFileClasses.coffee"

###* Gets a new selection if the currently selected row was deleted
  *
  * Is called by buildResources, if no row within the same parent left then the parent is selected
  * @todo Handle the case when the last root folder is deleted
  * @method getFileClass
  * @param {Object} rec  The raw resource
  * @returns {object} the class to be used to instantiate
  ###
export default getFileClass = (rec) =>
  if rec.isDir
    return FileSystemFile
  else
    extArr =  rec.name.split(".")[-1..]
    if extArr.length
      ext = extArr[0]
      if registeredFileClasses.hasOwnProperty(ext)
        return registeredFileClasses[ext]
    return FileSystemFile
