import fileSize from "filesize"
import {  isBinary } from 'istextorbinary'
import ComponentHub from "core/componentsHub.coffee"
mime = require('mime')
mime.define({'text/python': ['py', 'pyw']})
mime.define({'text/pandora-requirement': ['req']})
mime.define({'text/pandora-matrix': ['tmx']})
mime.define({'text/pandora-ad': ['ad','mf']})
mime.define({'text/r-script': ['r','rmd','plt']})
mime.define({'text/nonmem': ['ctl','fdata','nmt','mod','trn','dat']})
mime.define({'text/step': ['grd','phi','shk','shm','cor','coi','cov']})
mime.define({'text/other': ['vue']})
mime.define({'text/typescript': ['ts']},force = true)


###*
  * @typedef {Object} FileActionButtons
  * @property {string} name - the name of the button
  * @property {string} icon - Icon to display
  * @property {boolean} highlight - Icon to display
  * @property {boolean} disabled - whether it should be enabled or disabled
  * @property {boolean} hidden - whether it should be shown or not
  * @property {string} tooltip - A handy tooltip
  * @property {Function} click - The clickHandler

###


export class FileSystemFile
  #region Getters
  ###* virtual Field (Getter/Setter)
    * gets or sets BrowserMode via tabPinned
    * @name FileSystemFile#tabPinned
    * @property {boolean} tabPinned
  ###
  Object.defineProperty this::, "tabPinned",
    get: ->
      return this._tabPinned
    enumerable: true
    configurable: true
    set: (val) ->
      this._tabPinned = val

  ###*
    * @name FileSystemFile#actionButtons
    * @type {FileActionButtons[]}
    ###
  Object.defineProperty this::, "actionButtons",
    get: ->
      if this._editor?
        return this.createToolBarActions().concat(this._editor.createToolBarActions())
      return this.createToolBarActions()
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name FileSystemFile#cachedPath
    * @type {string}
    ###
  Object.defineProperty this::, "cachedPath",
    get: ->
      if !ComponentHub.FS.pathIsValid(@_path)
        @_path = ComponentHub.FS.pathByIno(@ino)
      return @_path
    enumerable: true
    configurable: true


  ###* virtual Field (Getter) returns the path
    * @name FileSystemFile#path
    * @type {string} path
    ###
  Object.defineProperty this::, "path",
    get: ->
      @_path = ComponentHub.FS.pathByIno(@ino)
      return @_path
    set: (newPath) ->
      @_path = newPath
    enumerable: true
    configurable: true



  ###* virtual Field (Getter) returns the path
    * @name FileSystemFile#parentPath
    * @type {string} parentPath
    ###
  Object.defineProperty this::, "parentPath",
    get: ->
      splitted = @path.split("/")
      return "/" + splitted[1..-2].join("/")
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the lastModified Unix timestamp into a locale string
    * @name FileSystemFile#lastModifiedFmt
    * @type {string} lastModifiedFmt
    ###
  Object.defineProperty this::, "lastModifiedFmt",
    get: ->
      if this.lastModified?
        return new Date(parseInt(this.lastModified)).toLocaleString()
      return ''
    enumerable: true
    configurable: true


  ###* virtual Field (Getter) returns the formatted pretty fileSize
    *  filesize(500);                        // "500 B"
    *  filesize(500, {bits: true});          // "4 kbit"
    *  filesize(265318, {base: 2});          // "259.1 KiB"
    *  filesize(265318);                     // "265.32 kB"
    *  filesize(265318, {round: 0});         // "265 kB"
    *  filesize(265318, {output: "array"});  // [265.32, "kB"]
    *  filesize(265318, {output: "object"}); // {value: 265.32, symbol: "kB", exponent: 1, unit: "kB"}
    *  filesize(1, {symbols: {B: "Б"}});     // "1 Б"
    *  filesize(1024);                       // "1.02 kB"
    *  filesize(1024, {exponent: 0});        // "1024 B"
    *  filesize(1024, {output: "exponent"}); // 1
    *  filesize(265318, {standard: "jedec"});  // "259.1 KB"
    *  filesize(265318, {base: 2, fullform: true}); // "259.1 kibibytes"
    *  filesize(12, {fullform: true, fullforms: ["байтов"]});  // "12 байтов"
    *  filesize(265318, {separator: ","});   // "265,32 kB"
    *  filesize(265318, {locale: "de"});   // "265,32 kB"
    * @name FileSystemFile#fileSizeFmt
    * @type {string} fileSizeFmt
    ###
  Object.defineProperty this::, "fileSizeFmt",
    get: ->
      if this.size?
        return fileSize(this.size, {round: 2})
      return ''
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns  the icon for review status
    * @name FileSystemFile#statusColumn
    * @type {string} statusColumn
    ###
  Object.defineProperty this::, "statusColumn",
    get: -> this.getStatusIcon()
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the treeId*
    * The treeId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtul space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name FileSystemFile#treeId
    * @type {string} treeId
    ###
  Object.defineProperty this::, "treeId",
    get: -> this.path
    enumerable: true
    configurable: true


  ###* virtual Field (Getter) returns the treeParentId
    * The treeParentId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name FileSystemFile#treeParentId
    * @type {string} treeParentId
  ###
  Object.defineProperty this::, "treeParentId",
    get: ->
      if this.hasOwnProperty("_treeParentId")
        return this._treeParentId
      else
        return this.parent
    enumerable: true
    configurable: true


  ###* virtual Field (Getter) returns the virtualPath*
    * The virtualPath may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name FileSystemFile#virtualPath
    * @type {string} virtualPath
    ###
  Object.defineProperty this::, "virtualPath",
    get: -> this.path
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name FileSystemFile#currentEditor
    * @type {object|null} currentEditor
    ###
  Object.defineProperty this::, "currentEditor",
    get: -> this._editor
    enumerable: true
    configurable: true
    set: (val) ->
      this._editor = val

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name FileSystemFile#currentEditorIsMonaco
    * @type {boolean} currentEditorIsMonaco
    ###
  Object.defineProperty this::, "currentEditorIsMonaco",
    get: ->
      if this._editor?
        if this._editor.editorType is 'monaco'
          return true
      return false
    enumerable: true
    configurable: true
  #endregion Getters


  ###*  Is the base class for any file displayed in the tree
    *
    *  This class should be overwritten by special files
    *  is instantiated
    * @constructor
    * @param {FileObject} fileObject
    ###
  constructor: (fileObject) ->
    ###* - true if the file resides within the workspace / tree
      * @type {boolean}
      ###
    this.isSettingsFile = false
    ###* - true if the file resides within the workspace / tree
      * @type {boolean}
      ###
    this.external = false
    ###* - Assign our name for easy checks
      * @type {string}
      ###
    this.className = this.constructor.name
    ###* - Our relative path
      * @type {string | null}
      ###
    this._path = ""  # @_path is the cached path, use with care !
    ###* - The raw file object obtained from FSW
      * @type {FileObject}
      ###
    this.blob = fileObject
    ###* - MimeType of the file
      * @type {string | null}
      ###
    this.mimeType = null
    ###* - Are we root ?
      * @type {boolean}
      ###
    this.isRoot =false
    ###* - True if we are an b64 encoded binary
      * @type {boolean}
      ###
    this.isB64 = false
    ###* - Our color in the FileBrowser
      * @type {string}
      ###
    this.treeColor = 'primary'
    ###* - Our icon in the FileBrowser
      * @type {string}
      ###
    this.treeIcon = 'mdi-file-document-outline'
    ###* - Are we a folderish object (Important for certain improve objects)
      * @type {boolean}
      ###
    this.isFolderish = false
    ###* - Are we a directory
      * @type {boolean}
      ###
    this.isDir = false
    ###* - What are we in detail
      * @type {string}
      ###
    this.kind = "file"
    ###* - Our file size
      * @type {number}
      ###
    this.size = 0
    ###* - filename w/o path
      * @type {string}
      ###
    this.name = ''
    ###* - The inode
      * @type {string}
      ###
    this.ino = ''
    ###* - The name we are displayed with
      * @type {string}
      ###
    this.displayName = ''
    ###* - Our contents
      * @type {string | Buffer | null}
      ###
    this.content=null
    ###* - When have we been last modified
      * @type {number}
      ###
    this.lastModified= new Date().valueOf()
    ###* - Is our content prepared for an editor or display
      * @type {boolean}
      ###
    this.contentPrepared = false
    ###* When true (default) the tab keeps open when a new file is selected in tree, when false, the tab containing this file will be replaced
      * @type {boolean}
      ###
    this.tabPinned = true
    ###* - The editor we are currretnly loaded in
      * @type {object|null}
      ###
    this._editor = null
    this.renderMode=false
    @update fileObject

  resetStates: ->
    this.renderMode=false
    this.tabPinned = true

  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    return []

  ###* updates this instance with fresh data
    *
    * update should be implemented on any derived class
    * when the resourcebrowser tree branch is refreshed, update is called
    * this keeps all informations of the object and just refreshes those where we get new data
    * @param {FileObject} rawData as returned from REST API
   ###
  update: (rawData)->
    if rawData.hasOwnProperty('content')
      if rawData.content isnt @content
        this.contentPrepared = false
    rawData && Object.assign this, rawData
    this.isFolderish = this.isDir
    if this.isFolderish
      this.kind="directory"
      this.treeIcon = 'mdi-folder-open-outline'
    else
      this.treeIcon = 'mdi-file-document-outline'
      if rawData.size?
        this.size = rawData.size
      this.displayName = this.name
      this.mimeType = @getMimeType()
      this.reindex()
    return


  ###*
   * @description Reindexes the file and put it into our Index
   * You have to recall this if you are dealing with special Filetypes which need special fields after super() in update()
  ###
  reindex: ->
    if !this.isFolderish
      unless this.mimeType?
        this.mimeType = @getMimeType()
      if this.mimeType and (this.mimeType.startsWith "text" or this.mimeType.endsWith("json")  or this.mimeType.endsWith("javascript"))
        if ComponentHub.FileSystemTree.watcherReady or !ComponentHub.FileSystemTree.indexFilePresent
          unless this.content?
            this.getContent()
          @unindex()
          ComponentHub.indexer.add(this)
          a=1
    return

  ###*
   * @description Removes us from search Index
  ###
  unindex: ->
    try
      res = ComponentHub.indexer.search(this.ino, { fields: ['id'] })
      if res.length
        ComponentHub.indexer.remove this
    catch e
      # never mind
    return



  ###* Saves the file back to FS with its current content
    *
    * **Important** Never save back binary files !!
    * @return {void}
   ###
  save: () ->
    if @content?
      ComponentHub.FS.writeFile(@path, @content, 'utf-8')
      @reindex()
      ComponentHub.storeActiveIndex()
      return

  ###* Kept for Compatibiltity with Async in ImproveResource
   * @return {string | null}
  ###
  getInitContent: ->
    return this.getContent()

  ###* Returns the Content of this File.
    *
    * this.content may be loaded already when the Filesystem was initialized, but this content is raw
    * When accessing this method the first time it etermines wheter the content is binary or not.
    * if it is binary and e.g an image it is converted to an inline data url so it may be displayed in image tags
    * the prepared content is cached in @content. when the file gets updated the cache is invalidated through
    * **this.contentPrepared**
    * @return {string | null} the usable content of the file
    ###
  getContent: ->
    if !@isFolderish
      unless @content?
        try
          @content = ComponentHub.FS.readFile(@path, 'utf-8')
          this.contentPrepared = false
        catch err
          console.error err
      # @ts-ignore
      if isBinary(@name, @content)
        @isB64 = true
        if !this.contentPrepared
          try
            if @getViewerType() is 'image'
              rawBuffer = ComponentHub.FS.readFile(@path, null)
              reducer = (data, byte) -> data + String.fromCharCode(byte)
              # @ts-ignore
              img = globalThis.btoa(new Uint8Array(rawBuffer).reduce(reducer, ''))
              @content = "data:#{this.mimeType};base64,#{img}"
            else
              rawBuffer = ComponentHub.FS.readFile(@path, null)
              @content = rawBuffer.toString('base64')
            this.contentPrepared = true
          catch e
            console.error(@path,e)
            this.contentPrepared = false
      else
        @isB64 = false
        this.contentPrepared = true
      if @content?
        return @content.toString()
    return null

  ###* based on the name of the resource the mime-type will be returned
    *
    * @return {string | null} String   like text/html, x-application/ms-word
    ###
  getMimeType: () ->
    if !@isFolderish
      if this.name.indexOf(".") >= 0
        # @ts-ignore
        this.mimeType = mime.getType(this.name.toLowerCase())
        return this.mimeType
    return null


  ###* determines and returns the type of viewer, needed to display resource contents
    *
    * @return {string}  String like: text, image, pdf
    ###
  getViewerType: () ->
    mimeType = this.getMimeType()
    if mimeType?
      mimeGroup = mimeType.split('/')[0].toLowerCase()
      switch mimeGroup
        when 'image', 'text'
          return  mimeGroup
        when 'application'
          type = mimeType.split('/')[1].toLowerCase()
          switch type
            when 'pdf'
              return type
            when 'javascript','json'
              return 'text'
            else
              return 'nonPreview'
        else
          return 'nonPreview'
    return 'nonPreview'

  ###* updates this instance and dependencies with fresh data
    *
    * @param {object} rawData as returned from REST API
    * @return {void} void
    ###
  deepUpdate: (rawData)->
    @update(rawData)
    return

  ###* Loads also our relatedObjects like Metadata and revisions
    *
    ###
  onDeepLoad: () ->
    @getContent()
    return

  ###* gets all subitems of this (folderish) resource
    *
    * mostly used by ResourceBrowser
    ###
  onExpand: () ->
    return


  ###* returns the status icon of this resource for representation
    *
    * @return {string} A String which represents icon and color
    ###
  getStatusIcon: () ->
    return 'horizontal_rule'

  ###* returns the directory we reside in
    *
    * @return {string}
    ###
  directory: ->
    splitted = @path.split("/")
    return "/" + splitted[1..-2].join("/")






