basicAuth = (uid,pwd) ->
  ba = "Basic " + btoa(uid + ":" + pwd)

tokenAuth = (token) ->
  ta = "Bearer " + token

export apiVersion = (improveStatusUrl) ->
  {
    "method": "GET",
    "url": improveStatusUrl ,
    "params": {
      "client": "local",
      "status": ""
    }
  }

export apiGetUserByName = (improveApiUrl,uid,pwd) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/users/username/#{uid}",
    "headers": {
      "Authorization":  basicAuth uid,pwd
    }
  }

export apiAuthenticate = (improveApiUrl,uid,pwd) ->
  {
    "method": "POST",
    "url": improveApiUrl + "/authentication",
    "headers": {
      "Authorization": basicAuth uid,pwd
    },
    "auth": {
      "username": uid,
      "password": pwd
    },
    "data": {}
  }

export apiAuthSupportsOAuth = (improveApiUrl,uid,pwd)->
  {
    "method": "GET",
    "url": improveApiUrl + "/authentication/supportsOAuth",
    "headers": {
      "Authorization": basicAuth uid,pwd
    },
    "auth": {
      "username": uid,
      "password": pwd
    }
  }
export apiAuthSupportsOAuthAccessToken =  (improveApiUrl,uid,pwd) ->
  {
    "method": "GET",
    "url": improveApiUrl + "/authentication/supportsOAuthAccessToken",
    "headers": {
      "Authorization": basicAuth uid,pwd
    },
    "auth": {
      "username": uid,
      "password": pwd
    }
  }

export apiVerifyToken = (improveApiUrl,token) ->
  {
    "method": "POST",
    "url": improveApiUrl + "/authentication",
    "headers": {
      "Authorization": tokenAuth token
    },
    "data": {}
  }

export apiRefreshToken = (improveApiUrl,token) ->
  # For the moment this is how it works for improve 2.6.x
  # Awful, but hopefully we get a cleaner solution soon
  {
    "method": "GET",
    "url": improveApiUrl + "/authentication/refreshToken",
    "headers": {
      "Authorization": "Bearer " + JSON.stringify(token)
    }
  }
