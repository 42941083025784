import ComponentHub from "core/componentsHub.coffee"
import {ImproveResource} from './ImproveResource.coffee'
import {apiGetRunserver, apiGetProcessRuns} from 'apis/improveREST/rawImprove.coffee'


export class ImproveProcess
  ###
  Base Class representing a ImproveStep extends [ImproveResource](ImproveResource.html)
  Usually contains [processes](processes.html)
  @constructor
  @param {Object} rawData as returned from REST API
  ###
  constructor: (rawData, stepResourceId, steprevisionId) ->
    @iWebType = 'ImproveProcess'
    @iWebContext = 'ImproveResource'
    @runs = []
    @variables = []
    @gridArguments = []
    @subProcesses = []
    @reproducible = false
    @commandLine = "process was never running: unknown cmd"
    @stepResourceId = stepResourceId
    @steprevisionId = steprevisionId
    @update rawData


  ###
  updates this instance with fresh data
  update should be implemented on any derived class
  when the resourcebrowser tree branch is refreshed, update is called
  this keeps all informations of the object and just refreshes those where we get new data
  @method update
  @param {String} rawData as returned from REST API

  @return {null} null
  ###
  update: (rawData)->
    rawData && Object.assign this, rawData

  ###
  loads all runs for this process
  also updated is the flag reproducable which comes from the runserver
  as well as commandLine which is filled as soon as the step was runned at leat one time
  @method update
  @param {String} rawData as returned from REST API

  @return {null} null
  ###
  loadRuns: ->
    srv = await ComponentHub.activeServer.doQuery apiGetRunserver @runserverId
    try
      @reproducible = srv.data.reproducible
      runs = await ComponentHub.activeServer.doQuery apiGetProcessRuns(@stepResourceId, @steprevisionId, @id), false
      if runs.status is 200
        if runs.data.length > 0
          @commandLine = runs.data[0].commandLine
    catch e
    # @note never mind
    finally
      return null
