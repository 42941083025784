# # Improve Server Interface
###
  # ## Server
  The Server class holds the connection to the improve server
  it is responsible for authentication, token renew, and pinging the server
  to get and verify it's online state
  You must not instantiate this class, this is done automatically in the [boot file](../../boot/configurationLoader.html)
  There also happens the initial proof of an eventually stored token
###

import axios from 'axios'
import {LocalStorage} from "quasar"
import {reactive , ref, toRefs} from "vue"
import ComponentHub from "core/componentsHub.coffee"
import {apiGetUserByName,apiVersion, apiAuthenticate, apiVerifyToken,apiRefreshToken,apiAuthSupportsOAuth,apiAuthSupportsOAuthAccessToken} from './statusauth.coffee'
import {apiGetMetaDataCharacters,apiGetUserGroupMemberShip,apiGetMetaDataDefinitions} from './rawImprove.coffee'
import {isV2atLeastV1, cl} from './tools.coffee'
import jwt_decode from "jwt-decode"
import {FileManager} from 'apis/FSAccess/LocalFiles.coffee'


###* WebApiCallBacks definition
  * @typedef {Object} WebApiCallBacks
  * @property {boolean=} initialized - is the callback object initialized
  * @property {NewFileCallBack} newRecord - callback to be called when a new File is created in the file system
  * @property {MovedFileCallBack} moveRecord - callback to be called when a  File is moved/renamed in the file system
  * @property {DeletedFileCallBack} delRecord - callback to be called when a  File is deleted in the file system
  * @property {ChangedFileCallBack} changeRecord - callback to be called when a  File is changed  in the file system
  * @property {WatcherReadyCallBack} watcherReady - callback to be called when the Filesystemwatcher is ready
  ###

###* QueryState definition
  * @typedef {Object} QueryState
  * @property {boolean=} isLoading - query is still loading
  * @property {number | null} status - http response code (200, 404 etc)
  * @property {object  | null} error - the last error message if there is one
  * @property {object | array | null} data - the retrieved data
  ###

export class Server

  ###
  @constructor {Object}
  @param {Object} config found in improve_config.json in the webserver root
  ###
  constructor: (config) ->
    this.parseConfig(config)
    #
    this.isImproveNewAuth = false
    this.userdata = {}
    this.version =  "version"
    this.online = false
    this.isLoading = false
    this.onlineIndicator =  'blue'
    this.onlineIcon =  'mood_bad'
    this.userMessage = ''
    this.errorReported = null
    this.fileManager = new FileManager(this.config)
    this.validateHost()
    this.decodedToken = null
    this.user =  ComponentHub.activeWorkspace.username or ''
    this.displayUser = this.user
    this.password = ComponentHub.activeWorkspace.ssPassword or ''
    this.decryptedToken = ''
    this.pingIntervall = null
    this.tokenRenewTimer = null
    this.supportsOAuth = false
    this.supportsOAuthAccessToken = false
    this.metaCharactersMapping = {}
    this.metaDataDefinitionMapping = {}
    ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveToken')
    .then((token) =>
      this.apiToken = token or ''
      ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveRefreshToken')
      .then((refreshToken) =>
        this.refreshToken = refreshToken or ''
        await this.processToken this.refreshToken
        ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveLoggedIn')
        .then((loggedIn) =>
          this.loggedIn = loggedIn or false
        )
      )
    )


  ###* Set all callbacks we have to call when something happens
    *
    * @param {WebApiCallBacks} callBacks an dictionary with callbacknames and the callback functions
    * @return {void} Description
    * @example
    *
    * callBacks = {
    *      newRecord: @onNewRecord
    *      moveRecord: @onMoveRecord
    *      delRecord: @onDelRecord
    *      changeRecord: @onChangeRecord
    *      watcherReady: @onWatcherReady
    * }
    *    improveFS.setFSCallBacks(callBacks)
    ###
  setFSCallBacks: (callBacks) ->
    this.callBacks = callBacks
    return

  onReady: ->
    if this.callBacks?
      this.callBacks.watcherReady()

  setLocalDirectory:  ->
    host = ComponentHub.activeWorkspace.improveHost
    @fileManager = new FileManager(this.config)
    await @fileManager.setNewHandle()

  isDevMachine: ->
    return window.location.origin.indexOf('localhost') > 0 or   window.location.origin.indexOf('127.0.0.1') > 0

  ###
    parses the configuration and sets the LocalStorage
    @param {Object} Configuration Object found in improve_config.json in the webserver root
    @return {null}
    ###
  parseConfig: (config) ->
    this.config = config
    {improveHost,improveApi,improveRepo} = this.config
    this.restricted = false
    if this.config.hasOwnProperty("restricted")
      this.restricted = window.location.origin is this.config.restricted
    this.dev=@isDevMachine()
    if improveHost.endsWith("/")
      improveHost = improveHost[0..-2]
    if improveApi.startsWith("/")
      improveApi = improveApi[1..-1]
    if improveRepo.startsWith("/")
      improveRepo = improveRepo[1..-1]
    this.improveApiUrl = "#{improveHost}/#{improveApi}"
    this.improveStatusUrl = "#{improveHost}/#{improveRepo}"
    this.host = improveHost

  ###
    returns a boolean and a string with online/offline flag and  the improve version of the server
    @return {boolean|string}
    ###
  getImproveVersion: () ->
    result = await this.doQuery apiVersion(this.improveStatusUrl)
    online = if result.status is 200 then true else false
    # Set errorReported to set state  color  on validateHost
    this.errorReported = result.error
    if online
      data = result.data || ''
      if data
        start = data.indexOf "Version:"
        end = data.indexOf "("
        version = data[start+9..end-1].trim()
      else
        version = '0.0.0-0'
    else
      version = '0.0.0-0'
    this.isImproveNewAuth = isV2atLeastV1 '2.6.0-0', version
    return {online,version}

  ###
    takes a refresh Token and ensures access Token and refresh token
    is set correctly in Localstorage. it also is responsible for setting a refresh interval or ping
    @param {String} token Mostly taken fromAPI call or LocalStorage
    @return {boolean} indicates a valid or invalid token
    ###
  processToken: (token) ->
    if token is '' or token is null
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name,'ImproveRefreshToken', '')
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveToken', '')
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name,'ImproveLoggedIn', false)
      return false
    now = Math.floor(Date.now() /1000)

    ###
      encapsultes different versions ofthe used token. In Improve < 2.6 a token was used which just is a large encrypted string
      while in later versions it is a json object.
      While the handling of both of them is equal at the time of this writing (v.2.6.0), this will change again in future versions.
      At the moment (2.6.0) the whole Json Object has to be sent for every request and for refreshing the token,even
      the json object contains a separate access and id token. In any case several differences are here already.
      While in the old version, the username is jwt_encoded within the access token, in the newer one the username for example is part of
      the json object and so on.
      @param {number} exp Unix Timestamp indicating when the token expires
      @return {number}
      ###
    getTokenRemainTime = (exp) ->
      if exp  < now
        # token is expired, clear Local Storage and return false
        await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveRefreshToken', '')
        await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveToken', '')
        await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveLoggedIn', false)
        await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveExpires', now-3600)
        await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveExpiresFmt',"expired!")
        return 0
      else
        return exp - now

    if Object(token) is token
      #This means we have to deal with the newer API >= 2.6.0
      sToken = JSON.stringify(token)
      if this.apiToken is sToken
        # if we've got the same again, take the stored value since due token cache at serverside we might get same token with same expires_in
        tokenExpires = ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveExpires') or now + token.expires_in
      else
        # if it's new, we really calculate a new expiration date
        tokenExpires = now + token.expires_in
      if this.supportsOAuth and this.supportsOAuthAccessToken
        # API >= 2.8.0.42 and OAuth supported
        this.apiToken = token.access_token
      else
        # API >= 2.6.0 and API < 2.8.0.42 or OAuth not supported
        this.apiToken = sToken

      this.user = token.userName
      #User is element of token
      tokenExpires = now + token.expires_in
    else
      # We are dealing with the old Auth API < 2.6.0
      # Api Access Token is token
      this.apiToken = token
      decodedToken =  jwt_decode token
      #User is encoded in token
      ###* @ts-ignore ###
      this.user = decodedToken.sub
      #Both variants have a expireTime encoded
      ###* @ts-ignore ###
      tokenExpires = decodedToken.exp


    await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveExpires', tokenExpires)
    ###* @ts-ignore ###
    await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveExpiresFmt',new Date(tokenExpires * 1000).toLocaleString())
    timeLeft = await getTokenRemainTime tokenExpires
    ComponentHub.ConnectionStatusLine.setTokenLifeTime(timeLeft)
    if timeLeft <= 0
      #The token we got is already expired
      return false
    else
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveUser', this.user)
      # Refresh happens with the whole token for the moment in 2.6
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveRefreshToken', token)
      # Api Access
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveToken', this.apiToken)
      # it is not expired yet, so we are logged in any case
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveLoggedIn', true)
      #this.loggedIn = true
      # the next renew should happen before it expires
      host =  ComponentHub.activeWorkspace.improveHost
      if this.fileManager.host isnt host
        this.fileManager = new FileManager(this.config)
        this.fileManager.readStoredHandle()

      renew = timeLeft - 2

      if this.tokenRenewTimer
        # if there is a timer, kill it
        clearTimeout this.tokenRenewTimer
      this.tokenRenewTimer = setTimeout () =>
        clearTimeout this.tokenRenewTimer
        await this.validateCurrentToken()
      , renew * 1000   # timers are dealing with milliseconds !
      return true

  ###
    get the token we stored so far and validate it again
    @return {Boolean} indicates a valid or invalid token
    ###
  validateCurrentToken: () ->
    # check for token
    refreshToken = await ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name,'ImproveRefreshToken')
    if JSON.stringify(refreshToken).replace(/\W/g, '') is ''
      if this.password.length
        return await this.validateUser @user,@password
    if typeof refreshToken is 'string'
      # API < 2.6.0
      result = await this.doQuery apiRefreshToken this.improveApiUrl, refreshToken
    else
      if this.supportsOAuth and this.supportsOAuthAccessToken
        # API >= 2.8.0.42 and OAuth supported
        # this fails  (https://scinteco.atlassian.net/browse/IM-5679) remove comments below if fixed and indent line in catch again
        #try
        #  result = await this.doQuery apiRefreshToken this.improveApiUrl, refreshToken.refresh_token
        #catch e
          # so we use the old method, if this is fixed serverside it will just work
        result = await this.doQuery apiRefreshToken this.improveApiUrl, refreshToken
        #  console.warn "The two 401 errors above may be ignored, it means refresh token at server side is not yet optimized (https://scinteco.atlassian.net/browse/IM-5679), used compatibility mode, Everything is fine"
      else
        # API >= 2.6.0 and API < 2.8.0.42 or OAuth not supported
        result = await this.doQuery apiRefreshToken this.improveApiUrl, refreshToken
    this.errorReported = result.error
    if result.status is 200
      # We got a new token, so we store it
      await this.processToken result.data
      this.loggedIn = true
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      this.onlineIndicator = "green"
      this.onlineIcon='person'
      this.userMessage = ''
      return true
    else if result.status is 204
      #Token is still valid, there was no new token provided,( may happen improve < 2.6.0)
      await this.processToken refreshToken
      this.loggedIn = true
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      this.onlineIndicator = "green"
      this.onlineIcon='person'
      this.userMessage = ''
      return true
    else
      if this.password.length
        if await this.validateUser @user,@password
          return true
      #Token was invalid
      await this.validateHost()
      await this.processToken ''  # '' causes processToken to clear LS and set us to LoggedOut
      this.password = ''
      this.loggedIn = false
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      this.onlineIndicator = "yellow"
      this.onlineIcon='person_off'
      this.userMessage = 'You are logged out. Your access token is expired or invalid'

      return false


  ###
    validates given uid/pwd against the server. only valid user/pwd combinations will get back a token
    which is then processed and validated
    @param {String} user
    @param {String} password
    @return {Boolean} indicates  valid or invalid credentials
    ###
  validateUser: (user,password) ->
    result = await this.doQuery apiAuthenticate this.improveApiUrl, user,password
    # Set errorReported to set state  color  on validateHost
    this.errorReported = result.error
    if result.status is 200
      await this.processToken result.data
      this.user = user
      this.password = password
      this.loggedIn = true
      this.onlineIndicator = "green"
      this.onlineIcon='person'
      this.userMessage = ''
      this.supportsOAuth = false
      this.supportsOAuthAccessToken = false
      oAuth = await this.doQuery apiAuthSupportsOAuth this.improveApiUrl, user, password
      oAuthToken = await this.doQuery apiAuthSupportsOAuthAccessToken this.improveApiUrl,user,password
      if oAuth.status is 200
        this.supportsOAuth = oAuth.data
      if oAuthToken.status is 200
        this.supportsOAuthAccessToken = oAuthToken.data
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      ComponentHub.ConnectionStatusLine.setServerState(true)
      ud = await this.doQuery apiGetUserByName this.improveApiUrl, user,password
      this.userdata = ud.data
      res = await this.doQuery apiGetUserGroupMemberShip this.userdata.id
      this.userdata['memberships'] = []
      this.userdata['FullWebAccess'] = false
      if res.status is 200
        this.userdata.memberships = res.data
      for ms in   this.userdata.memberships
        if ms.name is "FullWebAccess"
          this.userdata['FullWebAccess'] = true
          break
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveUserData', this.userdata)
      this.displayUser = ud.data.username
      host =  ComponentHub.activeWorkspace.improveHost
      #if @fileManager.host isnt host
      #  @fileManager = new FileManager(this.config)
      #  @fileManager.readStoredHandle()
      ComponentHub.ConnectionStatusLine.setUser(this.userdata)
      return true
    if result.status >= 500
      this.loggedIn = false

      this.onlineIndicator = "red"
      this.onlineIcon='person_off'
      this.userMessage = 'The server is responding, but reports an internal error'
      this.userdata = {}
      this.displayUser = 'Logged out'
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      ComponentHub.ConnectionStatusLine.setError(this.userMessage)

      return false
    else
      await this.validateHost()
      await this.processToken ''
      this.password = ''
      this.loggedIn = false
      this.onlineIndicator = "yellow"
      this.onlineIcon='person_off'
      this.userMessage = 'You are logged out.'
      this.userdata = {}
      this.displayUser = 'Logged out'
      ComponentHub.ConnectionStatusLine.setLoginState(this.loggedIn)
      ComponentHub.ConnectionStatusLine.setError(this.userMessage)
      return false


  getUserData: ->
    ud = await  ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name,  "ImproveUserData")
    if @user is ud.username
      @userdata = ud
      return ud


  ###
    Higher level method to validate given hot is valid and it is online
    @return {Boolean} indicates  online state of server
    ###
  validateHost: () ->
    {online,version} = await this.getImproveVersion()
    this.online = online
    this.version = version
    ComponentHub.ConnectionStatusLine.setServerVersion(version)
    if online

      this.onlineIndicator = "yellow"
      this.onlineIcon='person_off'
      this.userMessage = 'You are currently logged out.'
      ComponentHub.ConnectionStatusLine.setLoginState(false)
      ComponentHub.ConnectionStatusLine.setServerState(true)
      ComponentHub.ConnectionStatusLine.setError(this.userMessage)
    else
      this.onlineIndicator = "red"
      this.onlineIcon='cloud_off'
      this.userMessage = 'The server is currently not reachable. Contact your Administrator'
      ComponentHub.ConnectionStatusLine.setLoginState(false)
      ComponentHub.ConnectionStatusLine.setServerState(false)
      ComponentHub.ConnectionStatusLine.setError(this.userMessage)
      # If we are not online for any reason and there was an error in doQuery, this means
      # we have a serious network problem, so set the flag to BLACK
      if this.errorReported
        this.onlineIndicator = "black"
        this.onlineIcon='mobiledata_off'
        this.userMessage = 'The server is currently not reachable. Network Error'
        ComponentHub.ConnectionStatusLine.setLoginState(false)
        ComponentHub.ConnectionStatusLine.setServerState(true)
        ComponentHub.ConnectionStatusLine.setError(this.userMessage)
    return online


  ###
    The login also ensures, that the auth token is refreshed within the token expiration time
    @param {String} user
    @param {String} password
    @return {Boolean} indicates  valid or invalid credentials
    ###
  login: (user,password) ->
    # The login also ensures, that the auth token is refreshed within the token expiration time
    loggedIn = await this.validateUser(user,password)
    if loggedIn
      await this.getMetaCharacters()
      await this.getMetaDataDefinitions()
    return loggedIn


  ###
    Logs out the current user
    @return {null}
    ###
  logout: () ->
    if this.tokenRenewTimer?
      clearTimeout(this.tokenRenewTimer)
    await this.processToken ''
    this.password = ''
    if @dev
      await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name, 'ImproveDevPassWord','')
    this.loggedIn = false
    ComponentHub.ConnectionStatusLine.setLoginState(false)
    await this.validateHost()

  ###
    generates a fresh query State Object
    @return {QueryState}
    ###
  getDefaultQueryState: () ->
    isLoading: false  # Query is running at the moment
    status: 0      # http response code (200, 404 etc)
    error: null       # the last error message if there is one
    data: null

  ###* SaAme as FetchRoot, but manipulates the parentId of every Root Item to point to "virtualRoot"
   * this allows to load different brances into one single Tree.
   * If tree callbacks are already set newrecord will be called
   * Since this method is only used to load the first treelevel in case a workspce or resource was openend
   * which is NOT "/", not the root resource, it might be, that the user wants to combine different branches from different real tree levels
   * or different resources from different treelevels or both mixed up within one tree
   * in that case the real objects have different parents and different path lengths, but they all should appear
   * at the same level in the FileSystemTree.
   * To accomplish that, we assing a _vpath property only to the items in the (virtual) root which is always
   * / + name. All child objects of those return for virtualPath the _vpath if they own this property, if not, the one of their
   * parent + their name . So we have a virtual path to determine the inset levels in the treetable
   * @param {function} queryFn
   * @param {boolean} reportError - should errors be ignored or reported
   * @return {object | boolean}
  ###
  fetchInToVirtualRoot: (queryFn, reportError=true) ->
    result = await this.doQuery queryFn, reportError
    if result.status is 200
      if result.data?
        if this.callBacks?
          if Array.isArray(result.data)
            ###* @ts-ignore ###
            for entry in result.data
              entry._vpath = "/#{entry.name}"
              entry._treeParentId = "virtualRoot"
              this.callBacks.newRecord entry
          else
            result.data._vpath = "/#{result.data.name}"
            result.data._treeParentId = "virtualRoot"
            this.callBacks.newRecord result.data
        else
          if Array.isArray(result.data)
            ###* @ts-ignore ###
            for entry in result.data
              entry._vpath = "/#{entry.name}"
              entry._treeParentId = "virtualRoot"
          else
            result.data._vpath = "/#{result.data.name}"
            result.data._treeParentId = "virtualRoot"
        return result.data
    else
      await ComponentHub.PageIndex.alert "Improve", "the requested resource could not be retrieved. Server answered #{result.error}"
      return false
  ###* Fetch initial Data
   * Loads Root resources into the tree, those mark the first treelevel
   * _vpath is in this case always the path. _vpath is needed to calculate virtual paths
   * in case more resources/branches are loaded in parallel which does never happen in this method but in
   * fetchInToVirtualRoot. For details see there and in the virtualpath properties of ImproveResource
   * If tree callbacks are already set newrecord will be called
   * @param {function} queryFn
   * @param {boolean} reportError - should errors be ignored or reported
   * @return {object | boolean}
  ###
  fetchData: (queryFn, reportError=true) ->
    result = await this.doQuery queryFn, reportError
    if result.status is 200
      if result.data?
        if this.callBacks?
          if Array.isArray(result.data)
            ###* @ts-ignore ###
            for entry in result.data
              entry._vpath = entry.path
              this.callBacks.newRecord entry
          else
            result.data._vpath = result.data.path
            this.callBacks.newRecord result.data
        return result.data
    else
      await ComponentHub.PageIndex.alert "Improve", "the requested resource could not be retrieved. Server answered #{result.error}"
      return false



  ###
    The central method for doing any Server Query. Do not query the server with any other (maybe future) method.
    By using only this Class and method we can encapsulate the whole ret from future Improve Versions
    It also handles the states for the UI Interface to give the user a response if something went wrong
    @example
    ```
    import {improveServer} from 'boot/configurationLoader'
    ...
    result = await improveServer.doQuery apiGetSubTree( this.resourceId)
    return result.data
    ```
    @param {function} queryFn A function returned by the module with [ImproveAPI](../apis/rawImprove.html) definitions
    @param {boolean} reportError optional default true. If false in case of an error, NO red Bar is shown
                    This might be the case if the rest API reports 404 for example if no requested subitem is present
    @return {QueryState}
    ###
  doQuery: (queryFn, reportError=true) ->
  # get a fresh instance of the state object and make it reactive
    this.isLoading = true
    ###* type {QueryState} ###
    state =  this.getDefaultQueryState()
    fetch = () =>
      # fetch executes the query and handles errors
      try
        state.loading = true
        query = await queryFn
        response = await axios query
        state.data = response.data
        state.status = response.status
        this.onlineIndicator = "green"
        this.onlineIcon='person'
        ComponentHub.ConnectionStatusLine.setLoginState(true)
        ComponentHub.ConnectionStatusLine.setServerState(true)

        this.userMessage = ''
      catch error #Check here for Roche auth bug!
        if error.response and (error.response.status is 401 or error.response.status is 403)
          # if auth failed, retry authentication
          if !(@password and @password.length)
            # If password was lost or not given for any reason, get it from the workspace
            this.password = ComponentHub.activePlugin.workspace.ssPassword
            # and retry again
            if @password and @password.length
              if await this.validateUser(this.user,this.password)
                response = await axios queryFn
                state.data = response.data
                state.status = response.status
                this.onlineIndicator = "green"
                this.onlineIcon='person'
                this.userMessage = ''
                ComponentHub.ConnectionStatusLine.setLoginState(true)
                ComponentHub.ConnectionStatusLine.setServerState(true)
                ComponentHub.ConnectionStatusLine.setMessage(this.userMessage)
                state.loading = false
                return
          # otherwise abort
          console.error "The given credentials are wrong"
          state.status = error.response.status
          this.onlineIndicator = "red"
          this.onlineIcon='mobiledata_off'
          this.userMessage = 'The server is online, but reports an internal error'
          ComponentHub.ConnectionStatusLine.setLoginState(false)
          ComponentHub.ConnectionStatusLine.setServerState(true)
          ComponentHub.ConnectionStatusLine.setError(this.userMessage)
          state.loading = false
          return
        else if error.response and (error.response.status is 404)
          console.error "The resource could not be found"
          state.status = error.response.status
          state.loading = false
          return

        else if error.response and (error.response.status >= 500)
          console.error "the server reports an internal error"
          state.status = error.response.status
          this.onlineIndicator = "red"
          this.onlineIcon='mobiledata_off'
          this.userMessage = 'The server is online, but reports an internal error'
          ComponentHub.ConnectionStatusLine.setLoginState(true)
          ComponentHub.ConnectionStatusLine.setServerState(true)
          ComponentHub.ConnectionStatusLine.setError(this.userMessage)
          state.loading = false
          return
        else
          state.status = 0
        state.error = error
        detail = error.message
        if reportError
          if error.response
            if error.response.data
              if error.response.data.message
                detail = ", Detail: #{error.response.data.message}"
          this.onlineIndicator = "red"
          this.onlineIcon='mobiledata_off'
          this.userMessage = "Could not perform Operation: #{error.message} #{detail}"
          ComponentHub.ConnectionStatusLine.setLoginState(false)
          ComponentHub.ConnectionStatusLine.setServerState(false)
          ComponentHub.ConnectionStatusLine.setError(this.userMessage)

      finally
        state.loading = false

    reset = () ->
      # reset the state to free resources
      defaultState = this.getDefaultQueryState()
      state.isLoading = defaultState.isLoading
      state.error = defaultState.error
      state.data = defaultState.data
    # call fetch
    await fetch()
    # and return the whole bunch
    # the assignement to rState is syntatic sugar since toRefs(state)... and ...toRefs(state) is not
    # accepted by the linter of the IDE, so we can avoid an error is indicated by the IDE
    #rState = toRefs(state)
    # return the whole state with data errors and so on as well as the fetch and reset method
    this.isLoading = false
    return state

  getMetaCharacters: ->
    characters = []
    this.metaCharactersMapping = {}
    res = await this.doQuery apiGetMetaDataCharacters()
    if res.status is 200
      characters= res.data  or []
    for char in characters
      this.metaCharactersMapping[char.id] = char

  getMetaDataDefinitions: ->
    definitions = []
    this.metaDataDefinitionMapping = {}
    res = await this.doQuery apiGetMetaDataDefinitions()
    if res.status is 200
      definitions = res.data or []
    for md in definitions
      this.metaDataDefinitionMapping[md.name] = md
