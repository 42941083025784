import localforage from 'localforage'
import {toRaw} from 'vue'
import  CryptoJS  from 'crypto-js'
key = 'SDO8742934dfajshfajkuerzqiruztelkjfdshdfaldkjfh'
dbName = 'ScintecoClient'
localforage.config({ name: dbName})


encrypt = (data)  ->
  return  CryptoJS.AES.encrypt(data, key).toString()

decrypt = (data) ->
  bytes  = CryptoJS.AES.decrypt(data, key)
  return bytes.toString(CryptoJS.enc.Utf8)
#
###*
  * @method initStore
  * @description returns a localforage Instance from the given tablename
  * @param {string} storename
  * @return {LocalForage}
  ###
initStore = (storename) ->
  store = localforage.createInstance({name:dbName, storeName:storename})
  settings = await store.getItem('settings')
  unless settings?
    store.setItem 'settings', toRaw({})
  return store
#
###*
  * @method getSettings
  * @description returns the Settings for a plugin. Use 'globalApp' from plugin Name to retrieve the global App Settings
  * @param {string} pluginName
  * @return {object}
  ###
getSettings = (pluginName) ->
  store = localforage.createInstance({name:dbName, storeName:pluginName})
  settings = await store.getItem('settings')
  if settings? and settings.hasOwnProperty("ssPassword") and settings.ssPassword.length
    settings.ssPassword = decrypt settings.ssPassword
  return settings

#
###*
  * @method getGlobalPwd
  * @description returns the passwords for the given host
  * @param {string} hostName
  * @return {object}
  ###
getGlobalPwd = (hostName) ->
  store = localforage.createInstance({name:dbName, storeName:'credentialStore'})
  host = await store.getItem(hostName)
  return host

#
###*
  * @method getGlobalPwdForUser
  * @description returns the passwords for the given host
  * @param {string} hostName
  * @param {string} username
  * @return {String?}
  ###
getGlobalPwdForUser = (hostName, username) ->
  store = localforage.createInstance({name:dbName, storeName:'credentialStore'})
  host = await store.getItem(hostName)
  if host?
    if host.hasOwnProperty username
      return host[username]
  return null


#
###*
  * @method setGlobalPwd
  * @description sets the passwords for the given host
  * @param {string} hostName
  * @return {boolean}
  ###
setGlobalPwd = (hostName, passwords) ->
  store = localforage.createInstance({name:dbName, storeName:'credentialStore'})
  try
    await store.setItem hostName, toRaw(passwords)
    return true
  catch e
    alert "Saving Passwords failed: " + e.toString()
    return false

#
###*
  * @method savePasswordGlobal
  * @description There are users who are too lazy or not able to enter their password twice for the same server in different workspaces
  * Therefore we have to save serverpasswords per user globally beside the workspace. This hook manges that
  * @param {string} host
  * @param {string} user
  * @param {string} password
  * @param {boolean=} requestConfirmation
  * @return {void}
  ###
savePasswordGlobal = (host,user,password,requestConfirmation=true) ->
  globalSettings = await getSettings 'globalApp'
  if globalSettings.hasOwnProperty 'globalPasswords'
    if globalSettings['globalPasswords']
      globalCredentials = await getGlobalPwd(host)
      if globalCredentials?
        if globalCredentials.hasOwnProperty user
          if password.length is 0
            delete globalCredentials[user]
          else
            if decrypt(globalCredentials[user]) isnt decrypt(password)
              if requestConfirmation
                if confirm("The new password which was set recently is different from the globally stored password for user #{user} and host #{host}.\n\nShould this new password be set globally for this host?")
                  console.log "overwrite"
                  globalCredentials[user] = password
                else
                  console.log "no overwrite"
                  return
              else
                globalCredentials[user] = password
        else
          # host hasn't this user yet
          globalCredentials[user] = password
      else
        # we do not have this setting at all yet
        globalCredentials={}
        globalCredentials[user] = password
      await setGlobalPwd host, globalCredentials
  return



#
###*
  * @method setSettings
  * @description sets the Settings for a plugin. Use 'globalApp' from plugin Name to retrieve the global App Settings
  * @param {string} pluginName
  * @param {object} settings
  * @return {boolean}
  ###
setSettings = (pluginName, settings) ->
  store = localforage.createInstance({name:dbName, storeName:pluginName})
  try
    if settings? and settings.hasOwnProperty("ssPassword") and settings.ssPassword.length
      settings.ssPassword = encrypt settings.ssPassword
      await savePasswordGlobal(settings.improveHost,settings.username,settings.ssPassword)
    await store.setItem 'settings', toRaw(settings)
    return true
  catch e
    alert "Saving Settings failed: " + e.toString()
    return false

logoutAllWorkspaces = (host,user) ->
  allWks = await getAllWorkspaces()
  for wks in allWks
    if wks.improveHost is host and wks.username is user
      await setWorkspaceForceClearPassWord wks.workSpaceType, wks.name, wks

loginAllWorkspaces = (host,user,password) ->
  globalSettings = await getSettings 'globalApp'
  if globalSettings.hasOwnProperty 'globalPasswords'
    if globalSettings['globalPasswords']
      allWks = await getAllWorkspaces()
      for wks in allWks
        if wks.improveHost is host and wks.username is user and wks.saveSSPassword
          wks.ssPassword = password
          await setWorkspace wks.workSpaceType, wks.name, wks, false

#
###*
  * @method getAllWorkspaces
  * @description get all workspaces
  * @return {object}
  ###
getAllWorkspaces =  ->
  allWorkSpaces = []
  plugins = Object.keys(globalThis.loadedPlugins)
  for plugin in plugins
    plgwks = await getAllWorkspacesFromPlugin(plugin)
    allWorkSpaces = allWorkSpaces.concat(plgwks)
  return allWorkSpaces
#
###*
  * @method getAllWorkspacesFromPlugin
  * @description get all workspaces for a given plugin.
  * @param {string} pluginName
  * @return {array}
  ###
getAllWorkspacesFromPlugin = (pluginName) ->
  useGlobal = false
  globalSettings = await getSettings 'globalApp'
  if globalSettings.hasOwnProperty 'globalPasswords'
    if globalSettings['globalPasswords']
      useGlobal = true
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  retval = []
  try
    all = await store.keys()
    for entry in all
      if entry[..3] is "wsd_"
        wks = await store.getItem entry
        if wks.hasOwnProperty("ssPassword")
          if wks.ssPassword?
            if wks.ssPassword.length > 0
              wks.ssPassword = decrypt wks.ssPassword
          else
            wks.ssPassword = ""
        else
          wks.ssPassword = ""
        if useGlobal
          if wks.hasOwnProperty("saveSSPassword")
            if wks.saveSSPassword
              globalPassword = await  getGlobalPwdForUser wks.improveHost, wks.username
              if globalPassword?
                if globalPassword.length > 0
                  wks.ssPassword = decrypt globalPassword
        retval.push(wks)

    return retval
  catch e
    alert "Getting Workspaces  for plugin #{pluginName} failed: " + e.toString()
    return []
#
###*
  * @method getWorkspace
  * @description get the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @return {object}
  ###
getWorkspace = (pluginName, workSpaceName) ->
  useGlobal = false
  globalSettings = await getSettings 'globalApp'
  if globalSettings.hasOwnProperty 'globalPasswords'
    if globalSettings['globalPasswords']
      useGlobal = true
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    wks =  await store.getItem("wsd_#{workSpaceName}")
    if wks.hasOwnProperty("ssPassword")
      if wks.ssPassword?
        if wks.ssPassword.length > 0
          wks.ssPassword = decrypt wks.ssPassword
        else
          wks.ssPassword = ""
      else
        wks.ssPassword = ""
    if useGlobal
      if wks.hasOwnProperty("saveSSPassword")
        if wks.saveSSPassword
          globalPassword = await getGlobalPwdForUser wks.improveHost, wks.username
          if globalPassword?
            if globalPassword.length
              wks.ssPassword = decrypt globalPassword
    return wks
  catch e
    #alert "Getting Workspace #{workSpaceName} for plugin #{pluginName} failed: " + e.toString()
    return null
#
###*
  * @method setWorkspaceAndRename
  * @description store the workspce with given name for a given plugin.
  * renames the index if it is present
  * @param {string} pluginName
  * @param {string} oldWorkSpaceName
  * @param {string} workSpaceName
  * @param {object} workSpace
  * @return {boolean}
  ###
setWorkspaceAndRename = (pluginName,oldWorkSpaceName, workSpaceName, workSpace) ->
  workSpaceName = workSpaceName.toLowerCase()
  oldWorkSpaceName = oldWorkSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    if workSpace.hasOwnProperty("ssPassword")
      if workSpace.ssPassword?
        if workSpace.ssPassword.length > 0
          if workSpace.hasOwnProperty("saveSSPassword")
            if workSpace.saveSSPassword
              workSpace.ssPassword = encrypt workSpace.ssPassword
              await savePasswordGlobal(workSpace.improveHost,workSpace.username,workSpace.ssPassword,requestConfirmation)
            else
              workSpace.ssPassword=''
          else
            workSpace.ssPassword = encrypt workSpace.ssPassword
        else
          workSpace.ssPassword=''
      else
        workSpace.ssPassword=''
    else
      workSpace.ssPassword=''
    await store.setItem "wsd_#{workSpaceName}", toRaw(workSpace)
    old = await store.getItem "wsd_#{oldWorkSpaceName}"
    if old?
      await store.removeItem "wsd_#{oldWorkSpaceName}"
    idx = await store.getItem "idx_#{oldWorkSpaceName}"
    if idx?
      await store.setItem "idx_#{workSpaceName}", idx
      await store.removeItem "idx_#{oldWorkSpaceName}"


    return true
  catch e
    alert "Saving and Renaming Workspace failed: " + e.toString()
    return false

#
###*
  * @method setWorkspaceForceClearPassWord
  * @description store the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @param {object} workSpace
  * @return {boolean}
  ###
setWorkspaceForceClearPassWord = (pluginName, workSpaceName, workSpace) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    workSpace['ssPassword'] = ""
    await savePasswordGlobal(workSpace.improveHost,workSpace.username,workSpace.ssPassword)
    await store.setItem "wsd_#{workSpaceName}", toRaw(workSpace)
    return true
  catch e
    alert "Saving Workspace failed: " + e.toString()
    return false

#
###*
  * @method setWorkspace
  * @description store the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @param {object} workSpace
  * @param {boolean=} requestConfirmation
  * @return {boolean}
  ###
setWorkspace = (pluginName, workSpaceName, workSpace,requestConfirmation=true) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    if workSpace.hasOwnProperty("ssPassword")
      if workSpace.ssPassword?
        if workSpace.ssPassword.length > 0
          if workSpace.hasOwnProperty("saveSSPassword")
            if workSpace.saveSSPassword
              workSpace.ssPassword = encrypt workSpace.ssPassword
              await savePasswordGlobal(workSpace.improveHost,workSpace.username,workSpace.ssPassword,requestConfirmation)
            else
              workSpace.ssPassword=''
          else
            workSpace.ssPassword = encrypt workSpace.ssPassword
        else
          workSpace.ssPassword=''
      else
        workSpace.ssPassword=''
    else
      workSpace.ssPassword=''
    await store.setItem "wsd_#{workSpaceName}", toRaw(workSpace)
    return true
  catch e
    alert "Saving Workspace failed: " + e.toString()
    return false
#
###*
  * @method getIndex
  * @description get the fullTextIndex for the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @return {object}
  ###
getIndex = (pluginName, workSpaceName) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    return await store.getItem("idx_#{workSpaceName}")
  catch e
    alert "Getting Index #{workSpaceName} for plugin #{pluginName} failed: " + e.toString()
    return null
#
###*
  * @method setIndex
  * @description store the fullTextIndex for the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @param {string} index
  * @return {boolean}
  ###
setIndex = (pluginName, workSpaceName, index) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    await store.setItem "idx_#{workSpaceName}", toRaw(index)
    return true
  catch e
    alert "Saving Index failed: " + e.toString()
    return false
###*
  * @method getConnectionData
  * @description get the requested connection attribute for the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @param {string} setting
  * @return {object}
  ###
getConnectionData = (pluginName, workSpaceName,setting) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    conn = await store.getItem("conn_#{workSpaceName}")
    if conn? and conn[setting]
      return conn[setting]
    return null
  catch e
    alert "Getting Connection parameter #{setting} from #{workSpaceName} for plugin #{pluginName} failed: " + e.toString()
    return null
#
###*
  * @method setConnectionData
  * @description set the requested connection attribute for the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @param {string} setting
  * @param {any} data
  * @return {boolean}
  ###
setConnectionData= (pluginName, workSpaceName, setting, data) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    conn = await store.getItem("conn_#{workSpaceName}")
    if conn?
      conn[setting] = toRaw(data)
    else
      conn= {}
      conn[setting] =  toRaw(data)
    await store.setItem "conn_#{workSpaceName}", conn
    return true
  catch e
    alert "Saving Connection parameter #{setting} from #{workSpaceName} for plugin #{pluginName} failed:" + e.toString()
    return false


#
###*
  * @method deleteWorkspace
  * @description delete the workspce with given name for a given plugin.
  * @param {string} pluginName
  * @param {string} workSpaceName
  * @return {boolean}
  ###
deleteWorkspace = (pluginName, workSpaceName) ->
  workSpaceName = workSpaceName.toLowerCase()
  store = localforage.createInstance({name:dbName, storeName:pluginName.toLowerCase()})
  try
    await store.removeItem "wsd_#{workSpaceName}"
    await store.removeItem "idx_#{workSpaceName}"
    return true
  catch e
    alert "Deleting Workspace failed: " + e.toString()
    return false


export {
  initStore,
  getSettings,
  getWorkspace,
  getIndex,
  setSettings,
  setWorkspaceAndRename,
  setWorkspace,
  setIndex,
  deleteWorkspace,
  getAllWorkspacesFromPlugin,
  getAllWorkspaces,
  getConnectionData,
  setConnectionData,
  logoutAllWorkspaces,
  loginAllWorkspaces,
  getGlobalPwdForUser
}
