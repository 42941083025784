<template>
  <div
    :id="instancename"
    :class="isMainInstance ? 'hiddenWrapper' : 'monacoWrapper'"
  ></div>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import {useQuasar} from 'quasar'
import { Editorng  } from "./editorng.coffee"
export default defineComponent({
  name: 'editorng',
  emits: ['saved','gutterClick']
  props:
    instancename:
      type: String
      default: 'editorng'
    file:
      default: null
      type: Object
    compareWith:
      default: null
      type: Object
    fullHeight:
      default: true
      type: Boolean
    isMainInstance:
      type: Boolean
      default: false
  setup: (props,{ emit }) ->
    $q = useQuasar()
    editorng = reactive(new Editorng({props:props, emit:emit, quasar:$q}))
    editorng.vueBinder()
    onMounted(() => editorng.mounted())
    onUnmounted(() => editorng.unmounted())
    watch( () =>
      $q.dark.isActive
    ,(current,old) =>
      editorng.darkSelector(current)
    ,{immediate:true}
    )
    watch( () =>
      props.file
    ,(current,old) =>
      editorng.changeFile(current)
    ,{immediate:true}
    )
    watch( () =>
      props.compareWith
    ,(current,old) =>
      editorng.changeCompareWith(current)
    ,{immediate:true}
    )

    return {editorng}

})
</script>
<style lang="sass">
.monacoWrapper
  height: 100%
  width:100%
.hiddenWrapper
  height: 0px
  max-height: 0px
  width:100%

.reviewMarginClass::before
  background: transparent
  content: "🔰"

.reviewContentClass
  background: lightblue

.box
  width: 90%

.clens
  text-decoration: underline
  font-weight: normal
  font-size: small
  font-style: oblique
  color: #1976d2

.improve-comment-icon
  font-size: small


  margin-right: 7px
</style>
