import ComponentHub from "./componentsHub.coffee"
###*
* @typedef {object} VueClassOptions
* @property {object=} props - Vue Component Properties
* @property {Function=} emit - The emit Function
* @property {object=} refs  - any refs
* @property {object=} quasar - A quasar Object which enables us to use Quasar plugins like notify, dark etc
###


export default class CompositionBase
  ###* virtual Field (Getter) returns the actionButtons
    * @name FileSystemFile#actionButtons
    * @type {FileActionButtons[]}
    ###
  Object.defineProperty this::, "actionButtons",
    get: ->
      return this.createToolBarActions()
    enumerable: true
    configurable: true
  ###* virtual Field (Getter) returns the actionButtons
    * @name FileSystemFile#actionButtons
    * @type {FileActionButtons[]}
    ###
  Object.defineProperty this::, "titleButtons",
    get: ->
      return this.createTitleActions()
    enumerable: true
    configurable: true


  ###*  (Getter/Setter)
    * gets/sets the currently open Workspace
    * @name CompositionBase#workSpaceName
    * @type {string | null}
    ###
  Object.defineProperty this::, "workSpaceName",
    get: ->
      return this._workSpaceName
    set: (workspaceName) ->
      this._workSpaceName = workspaceName
  ###*  (Getter/Setter)
    * returns the currently  open Workspace File
    * @name CompositionBase#workspaceFile
    * @type {string | null}
    ###
  Object.defineProperty this::, "workspaceFile",
    get: ->
      return this._workspaceFile
    set: (workspaceFile) ->
      this._workspaceFile = workspaceFile

  ###*
   * @param {VueClassOptions=} options
   * @constructor
  ###
  constructor: (options) ->
    ###*
      * @type {string | null}
      ###
    @instancename=null
    @props=null
    @emit=null
    @ref=null
    @quasar=null
    this.isUtil=true
    if options?
      if options.props?
        @props = options.props
        options.props && Object.assign this, options.props
        #Any Component which is not here to Edit a resource, must have the isUtil prop
        # otherwise it will be registered as editor
        if !this.isUtil
          if this.hasOwnProperty("file")
            ###* @ts-ignore ###
            this.file.currentEditor = this
          ###* @ts-ignore ###
          if this.hasOwnProperty("node") and this.node?
            ###* @ts-ignore ###
            this.node.currentEditor = this
          if this.hasOwnProperty("resource")
            ###* @ts-ignore ###
            this.resource.currentEditor = this
          if this.hasOwnProperty("revision")
            ###* @ts-ignore ###
            this.revision.currentEditor = this
      if options.emit?
        @emit = options.emit
      if options.refs?
        @refs = options.refs
        options.refs && Object.assign this, options.refs
      if options.quasar?
        @quasar = options.quasar
    this.ComponentHub = ComponentHub
    this._workSpaceName = null
    this._workspaceFile = null
    this.isDesktop = this.ComponentHub.isDesktop


  ###* Returns our Left Pane actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the MainBar (where explorer and Search are placed)
   * @return {FileActionButtons[]}
  ###
  createTitleActions: ->
    return []
  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    return []

  ###*
   * @description This has to be overwritten in PluginContainers to get a notification when the tab containing the container is selected
  ###
  onTabActivated: (value)->
    console.log "onTabActivated" , value


  ###*
   * Binds all member functions to this at the moment of calling.
   * This should be called after the object creation in the vue template
   * if any vue components in the template are calling methods inside us
   * @example
   * setup: (props) ->
   *  instance = reactive(new ThisClass({props:props}))
   *  instance.vueBinder()
   *  onMounted(() => instance.mounted())
   *  return {instance}
   *
   *
   *
   ###
  vueBinder: ->
    getAllMethods = =>
      props = []
      obj = this
      loop
        props.push(Object.getOwnPropertyNames(obj)...)
        unless obj = Object.getPrototypeOf(obj)
          break
      return props.sort().filter (e, i, arr) =>
        if e!=arr[i+1] and typeof this[e] is 'function'
          return true
    for method in getAllMethods()
      this[method] = this[method].bind(this)
    return

  ###*
   * @return {void}
   ###
  mounted: ->
    if @props.instancename?
      instancename = @props.instancename
    else if this.instancename?
      instancename = this.instancename
    else
      return
    this.ComponentHub[instancename] = this
    return

  ###*
   * @return {void}
   ###
  unmounted: ->
    if @props.instancename?
      instancename = @props.instancename
    else if this.instancename?
      instancename = this.instancename
    else
      return
    if this.ComponentHub.hasOwnProperty(instancename)
      delete ComponentHub[instancename]
    return
