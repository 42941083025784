###* This is called from FileSystemBrowser to instantiate automatically the correct FileClass for any new File
  *
  * arriving through FS or RESt or whatever
  * Do not forget to register your specialized file classes in the registeredFileClasses module
  * @module components/FileSystemBrowser/FileClasses
  ###
import {ImproveResource} from "./resourceTypes/ImproveResource.coffee"
import {ImproveProcess} from "./resourceTypes/ImproveProcess.coffee"
import {ImproveReview} from "./resourceTypes/ImproveReview.coffee"
import {ImproveStep} from "./resourceTypes/ImproveStep.coffee"


###* Gets a new selection if the currently selected row was deleted
  *
  * Is called by buildResources, if no row within the same parent left then the parent is selected
  * @todo Handle the case when the last root folder is deleted
  * @method getFileClass
  * @param {object} rec  The raw resource
  * @returns {object} the class to be used to instantiate
  ###
export default getFileClass = (rec) =>
  if rec.isDir or rec.isFolderish
    return ImproveResource
  else
    switch rec.nodeType
      when "Review" then return ImproveReview
      when "Step" then return ImproveStep
      when "Process" then return ImproveProcess
      else return ImproveResource
