parseVersionString = (vString) ->
  version = vString
  splitted = version.split("-")
  ver = splitted[0]
  build = parseInt(splitted[1])
  mmm = ver.split(".")
  numericalVersion =
    build : parseInt(splitted[1])
    major : parseInt(mmm[0])
    sub : parseInt(mmm[1])
    minor : parseInt(mmm[2])
  return numericalVersion

export isV2atLeastV1 = (vString1, vString2) ->
  # Tells whether the second given version string in Format "2.6.0-123" is at least as high as string 1
  numV1 = parseVersionString(vString1)
  numV2 = parseVersionString(vString2)
  if numV2.major < numV1.major
    return false
  else if numV2.major > numV1.major
    return true
  else
    if numV2.sub < numV1.sub
      return false
    else if numV2.sub > numV1.sub
      return true
    else
      if numV2.minor < numV1.minor
        return false
      else if numV2.minor > numV1.minor
        return true
      else
        if numV2.build < numV1.build
          return false
        else
          return true


export cl = () ->
  stack = ''#(new Error("StackLog")).stack

