# # ReviewEntry
import ComponentHub from "core/componentsHub.coffee"
import {ImproveResource} from './ImproveResource.coffee'
import {apiGetResourceContent, apiGetRevisionHistory, apiGetRevisionContent, apiGetResourceMetadata,apiGetResource,
  apiGetRevisionMetaData, apiGetRootDirWithMeta, apiGetResourceByPath, apiGetSubTree,apiGetSubTreeWithMeta,apiReviewApproveEntry, apiReviewRejectEntry,apiGetReviewEntry} from 'apis/improveREST/rawImprove.coffee'
import getImproveClass from "components/ImproveBrowser/ImproveClasses.coffee"

export class ReviewEntry extends ImproveResource
  ###* virtual Field (Getter)
    * @name ReviewEntry#disableApproveButtonForCurrentUser
    * @type {boolean}
    ###
  Object.defineProperty this::, "disableApproveButtonForCurrentUser",
    get: ->
      found = false
      for state in this.states
        currUser = ComponentHub.activeServer.userdata.name
        if state.reviewer.username is currUser and state.status is "Approved"
          found = true
      return found
    enumerable: true
    configurable: true

  ###* virtual Field (Getter)
    * @name ReviewEntry#disableRejectButtonForCurrentUser
    * @type {boolean}
    ###
  Object.defineProperty this::, "disableRejectButtonForCurrentUser",
    get: ->
      found = false
      for state in this.states
        currUser = ComponentHub.activeServer.userdata.name
        if state.reviewer.username is currUser and state.status is "Rejected"
          found = true
      return found
    enumerable: true
    configurable: true

  ###* virtual Field (Getter)
    * @name ReviewEntry#userIsReviewer
    * @type {boolean}
    ###
  Object.defineProperty this::, "userIsReviewer",
    get: ->
      try
        for reviewer in this.inReview.reviewers
          if ComponentHub.activeServer.userdata.name is reviewer.username
            return true
        return false
      catch e
        return false
    enumerable: true
    configurable: true
  ###
    * virtual Field (Getter) returns the treeId
    * The treeId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtul space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name  ReviewEntry#treeId (overwritten)
    * @type {string}
    ###
  Object.defineProperty this::, "treeId",
    get: -> this.id
    enumerable: true
    configurable: true
  ###
    * virtual Field (Getter) returns the treeParentId
    * The treeParentId may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name  ReviewEntry#treeParentId (overwritten)
    * @type {string}
    ###
  Object.defineProperty this::, "treeParentId",
    get: -> this.treeParent
    enumerable: true
    configurable: true
  ###
    * virtual Field (Getter) returns the virtualPath*
    * The virtualPath may vary depending of the resource iWebType, since e.g. Review Entries are
    * displayed in a virtual space. This property is needed for building up the
    * tree in the resource browser correctly
    * @name  ReviewEntry#virtualPath (Overwritten)
    * @type {string}
    ###
  Object.defineProperty this::, "virtualPath",
    get: -> "#{this.vpath}/#{this.name}"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the path
    * @name ReviewEntry#renderMode
    * @type {boolean}
    ###
  Object.defineProperty this::, "renderMode",
    get: ->
      if ComponentHub.layoutTree.activePanel.tabs[this.id]?
        rm = ComponentHub.layoutTree.activePanel.tabs[this.id].renderMode
        console.log "ReviewEntry RenderMode:" , rm
        return rm
      return false
    enumerable: true
    configurable: true

  ###
    * Base Class representing a ReviewEntry
    * if we are a pure reviewEntry we have a resource property, if we are a revision object of an entry we are a resource
    * Therefore we need to switch in the constructor when calling super
    * @constructor
    * @param {Object} rawData as returned from REST API
    * @param {ImproveReview} review  [ImproveReview](ImproveReview.html)* the review this entry belongs to
    ###
  constructor: (rawData, review) ->
    if rawData.resource?
      super rawData.resource
      this.resource = rawData.resource
    else
      super rawData
      this.resource =null
    this.iWebType = 'ReviewEntry'
    this.iWebContext = 'ImproveReview'
    this.treeColor = 'positive'
    this.inReview = review
    this.id = rawData.id
    this.status = rawData.status
    this.states = rawData.states
    this.comments = this.addNameToComments(rawData.comments)
    this.treeParent = review.treeId
    this.vpath = review.virtualPath
    this.commentValidityTo = 0
    this.isEditable = false


  addNameToComments: (comments) ->
    for entry in comments
      entry.resourceName= this.name
    return comments


  ###
    * Loads also our relatedObjects like Metadata and revisions
    ###
  onDeepLoad: ()->
    if this.resource?
      res = await ComponentHub.activeServer.doQuery apiGetResource(this.resource.resourceId), false
      if res.status is 200
        rec = res.data
        fileClass = getImproveClass(rec)
        resource = new fileClass rec
        this.blob = resource
      try
        await this.blob.onDeepLoad()
        this && Object.assign this.blob, this
        this.blob && Object.assign this, this.blob
        ComponentHub.FileSystemTree.onChangeRecord this
      catch e
        console.warn this.name, "has no blob!"
    return

  refreshStates: () ->
    res = await ComponentHub.activeServer.doQuery apiGetReviewEntry(this.inReview.resourceId, this.id)
    if res.status is 200
      rec = res.data
      this.status=rec.status
      this.states = rec.states

  ###*
    * fetches an array of resources with all our revisions
    * Every revision gets flagged as revision (isRevision=true)
    * and gets itself a reference to the list of all other revisions
    * In this overwritten method we also set a flag commentValidityTo
    * always to the lastModifiedOn of the next higher revision to get
    * a timeframe for valid comments for this revision which then is
    * @lastModifiedOn <= comment.createdAt < nextHigherRevision.lastModifiedOn or 999999999999 if there is no higher one
    * (rev.revisionList = this.revisionList)
    ###
  getRevisionHistory: () ->
    result = await ComponentHub.activeServer.doQuery apiGetRevisionHistory(this.resourceId)
    fetched = result.data
    this.revisionList = []
    i=0
    for revision in fetched
      merged = Object.assign({}, this, revision)
      revision.comments = this.comments
      rev = new ReviewEntry(revision,this.inReview)
      rev.id = merged.id
      rev.refreshStates()
      rev.resource=this.resource
      #console.log rev.lastModifiedOn
      if i>0
        rev.isEditable = false
        rev.isRevision=true
      i = i + 1
      thisEntry=this.revisionList.length
      if thisEntry is 0
        rev.commentValidityTo = 9999999999999
      else
        rev.commentValidityTo = parseInt(this.revisionList[thisEntry-1].lastModifiedOn)-1
      this.revisionList.push(rev)
      rev.revisionList = this.revisionList

  ###
    returns only the comments for this specisfic revision
    Since comments are currently bound to the review Entry
    It is a bit tricky to show only those comments for a resource which have been
    created while this revision was actual
    In getRevisionHistory we set a flag commentValidityTo to one microsecond below
    the lastModifiedOn of the next higher revision
    If we have to deal with the current revision which might be not flagged as revision, we set
    the validTo date infinite and take as in the other cases @lastModifiedOn as the lower boundary
    @return {Array} Array, revisions
    ###
  commentsForRevision:() ->
    if not @isRevision
      validTo = 9999999999999
    else
      validTo = @commentValidityTo
    retval = []
    for comment in @comments
      if comment['createdAt'] >= parseInt(@lastModifiedOn) and comment['createdAt'] <= validTo
        retval.push(comment)
    return retval

  ###
    Marks the review Item as approved
    @method onApproved
    @return {boolean}
    ###
  onApproved: (comment) ->
    console.log "Review Approval for #{this.resourceId}"
    res = await ComponentHub.activeServer.doQuery apiReviewApproveEntry this.inReview.resourceId, this.id, comment
    if res.status is 200
      #@status='Approved'
      this.refreshStates()
      ComponentHub.FileSystemTree.onChangeRecord this
      console.log "approval received"
    return res.status is 200
  ###
    Marks the review Item as rejected
    @method onRejected
    @return {boolean}
    ###
  onRejected: (comment)->
    console.log "Review Rejection for #{this.resourceId}"
    res = await ComponentHub.activeServer.doQuery apiReviewRejectEntry this.inReview.resourceId, this.id,comment
    if res.status is 200
      #@status='Rejected'
      this.refreshStates()
      ComponentHub.FileSystemTree.onChangeRecord this
      console.log "reject received"
    return res.status is 200

  ###
    returns the status icon of this resource for representation
    @method getStatusIcon
    @return {String} A String which represents icon and color
    ###
  getStatusIcon: () ->
    if @status is "Approved"
      return 'mdi-check-circle-outline|green'
    else if @status is "Rejected"
      return 'mdi-close-circle-outline|red'
    return 'circle|primary'


