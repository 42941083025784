import {FileSystemFile} from './FileSystemFile.coffee'
import ComponentHub from "core/componentsHub.coffee"
###*
 * @augments FileSystemFile
  ###
export class ExternalFileSystemFile extends FileSystemFile
  ###* virtual Field (Getter) returns the path
    * @name ExternalFileSystemFile#path
    * @type {string} path
    ###
  Object.defineProperty this::, "path",
    get: ->
      return @_path
    set: (newPath) ->
      @_path = newPath
    enumerable: true
    configurable: true

  ###*  Is the base class for any file displayed which is NOT in the tree
    *
    *  This class should be overwritten by special files
    *  is instantiated
    * @constructor
    * @param {FileObject} fileObject
    ###
  constructor:  (fileObject) ->
    super(fileObject)
    ###* - true if the file resies within the workspace / tree
      * @type {boolean}
      ###
    this.external = true


  ###* Saves the file back to FS with its current content
    *
    * **Important** Never save back binary files !!
    * @return {void}
   ###
  save: () ->
    if @content?
      ComponentHub.FS.osWriteFile(@path, @content, 'utf-8')
      return
