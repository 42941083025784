import {toRaw} from "vue"
import ComponentHub from "core/componentsHub.coffee"
import {LSSettingsFileObject} from "core/lsSettingsObject.coffee"
import {SettingsFile} from "components/FileSystemBrowser/SettingsFile.coffee"



export default class PluginBase
  ###* virtual Field (Getter)
    Do we have ChildPanes ?
    * @name IndexPage#contentHeaderVisible
    * @type {boolean}
    ###
  Object.defineProperty this::, "usesContentHeader",
    get: ->
      return this._usesContentHeader
    set: (val) ->
      this._usesContentHeader = val
  ###* virtual Field (Getter)
    Do we have ChildPanes ?
    * @name IndexPage#contentFooterVisible
    * @type {boolean}
    ###
  Object.defineProperty this::, "usesContentFooter",
    get: ->
      return this._usesContentFooter
    set: (val) ->
      this._usesContentFooter = val
  ###* virtual Field (Getter)
    Do we have ChildPanes ?
    * @name IndexPage#contentFooterVisible
    * @type {boolean}
    ###
  Object.defineProperty this::, "usesContentSideBar",
    get: ->
      return this._usesContentSideBar
    set: (val) ->
      this._usesContentSideBar = val
  ###* virtual Field (Getter/Setter) loadingState for pluginButton
    * gets or sets loadingState for pluginButton
    * @name Pandora#buttonLoading
    * @property {boolean} buttonLoading
    ###
  Object.defineProperty this::, "buttonLoading",
    get: ->
      return this._buttonLoading
    enumerable: true
    configurable: true
    set: (val) ->
      this._buttonLoading = val

  ###*
   * @constructor
  ###
  constructor:->
    #region mandatory properties
    this.name = "plugin"
    this.icon = "rule"
    this.mainTitle="PLuginTitle"
    this.description="An empty plugin"
    this.author="© scinteco.com 2022"
    this.version="0.0.1"
    this.longDescription="Tell us about the plugin here"
    this.isDesktop=ComponentHub.isDesktop
    this.workspace = {}
    this.settingsFileName = this.name
    this.workspaceName = ''
    this.indexFileName = ''
    this.workspaceFileName = ''
    this.mainNavigationWidget='ImproveBrowser'
    this.webApi='ImproveREST'
    #
    ###* Mandatory for Plugins. holds the plugins current WorkspaceSettings SettingsFile Instance
      * @typeof {LSSettingsFileObject | null}
      ###
    this.workspaceFile = null
    #
    ###* Mandatory for Plugins. holds the plugins current WorkspaceSettings FileObject Instance
     * @type {FileObject | null}
     ###
    this.workspaceRawFile = null
    #
    ###* Mandatory for Plugins. holds the plugin Settings FileObject Instance
     * @type {FileObject | null}
     ###
    this.settingsRawFile = null
    #
    ###* Mandatory for Plugins. holds the plugin SettingsFile Instance
      * @typeof {LSSettingsFileObject | null}
      ###
    this.settingsFile = null
    this.settings = null
    #
    ###* Mandatory for Plugins.  If the plugin deals with its own filetypes, it should provide an indexer model., if not set this to null
      * @type {SearchIndexModel}
      ###
    this.indexerModel =
      idField: 'ino'
      fields: ['name', 'content'] # fields to index for full-text search
      storeFields: ['name', 'cachedPath'] # fields to return with search result
    #
    ###* Mandatory for Plugins.  This is the third Button on the Plugin Item shown in the Main Navigation Plugins Pane
      * @type {object}
      ###
    this.pluginButton=
        icon: "more_vert"
        tooltip: "No function"
        click: () =>
        disabled: !this.workspaceFile
    #
    ###* Mandatory for Plugins. Holds a flag which should be true when the 3rd additional PluginButton performs a long running Task
      * @type {boolean}
      ###
    this._buttonLoading = false
    ###* Mandatory for Plugins.  This are the fileTypes this Pluguin handles
      * @type {object}
      ###
    this.registeredFileTypes = {}
    ###* Mandatory for Plugins.  Does this plugin use the shared contentheader for teleport
      * @type {boolean}
      ###
    this._usesContentHeader=false
    ###* Mandatory for Plugins.  Does this plugin use the shared content footer  for teleport
      * @type {boolean}
      ###
    this._usesContentFooter=false
    ###* Mandatory for Plugins.  Does this plugin use the shared content side bar for teleport
      * @type {boolean}
      ###
    this._usesContentSideBar=false
    this.explorerPanes=[]


  #region Workspace and Settings Handling

  ###* Mandatory for Plugins. Is called from MainTemplate when everythin is setup
    * @return {*}
    ###
  onAppReady: ->
    settings = await ComponentHub.dbGetSettings this.name
    if !settings.hasOwnProperty("pluginsettings")
      ComponentHub.dbSetSettings this.name, @pluginSettingsTemplate()
    this.settings = await ComponentHub.dbGetSettings this.settingsFileName
    this.settingsFile = new LSSettingsFileObject this.settingsFileName, this.settings , this.pluginSettingsSchema()


  ###* Mandatory for Plugins. Will be called from the IndexPage when User selects new Workspace from the menu
    * @return {LSSettingsFileObject}
    ###
  onNewWorkspace: ->
    this.workspaceFile = new LSSettingsFileObject this.name, this.workSpaceTemplate(), this.workSpaceSchema()
    this.workspaceFile.displayName =  this.workSpaceTemplate().name
    this.workspace = this.workspaceFile.model
    return this.workspaceFile

  ###* Mandatory for Plugins. Will be called from the Settingsfile on save
    * It is recommended to pickup the provided workspace settings and ask the user to (re) open the workspace
    * @param {object} workspace
    ###
  onWorkSpaceSaved: (workspace)->
    if workspace.name is this.workspace.name
      this.workspace = workspace
      msg = "Do you like to reopen the workspace now"
    else
      msg = "Do you like to open the workspace now"
    workspaceName = workspace.name
    indexFile = await ComponentHub.dbGetIndex this.name, workspaceName
    ComponentHub.PageIndex.confirmBox({
      title: 'Workspace saved',
      message: msg,
      cancel: true,
      persistent: true
    }, () =>  # ok was pressed
      ComponentHub.PageIndex.mainNavigation='explorer'
      ComponentHub.PageIndex.onWorkSpaceFileOpened workspaceName,this.name
    , () => # cancel was pressed
    )
    return


  ###* Mandatory for Plugins. Opens the workspace
   * @description This is mandatory for all PluginClasses
   * Opens the workspace. If a plugin should not open, just overwrite with an no op
   * @param {string} workspaceName
   * @return {boolean}
   ###
  openWorkSpace: (workspaceName) ->
    this.folders = {}
    this.workspaceName = workspaceName
    this.workspace = await ComponentHub.dbGetWorkspace this.name, workspaceName
    if this.workspace.hasOwnProperty("ssPassword")
      this.workspace.ssPassword = ComponentHub.decrypt this.workspace.ssPassword
    index = await ComponentHub.dbGetIndex this.name, workspaceName
    this.workspaceFile = new LSSettingsFileObject this.name, this.workspace, this.workSpaceSchema()
    this.workspaceFile.displayName = workspaceName
    if this.workspace is Object({})
      alert "The workspace File contains no definition. (is empty or corrupt))"
      ComponentHub.activeWorkspace  = null
      return false
    if this.checkForValidWorkspaces()
      ComponentHub.activeWorkspace  = this.workspace
      ComponentHub.FileSystemTree.onRootDirChanged "/", "0"
      return true
    ComponentHub.activeWorkspace  = null
    return false

  ###* Recommended for Plugins. Checks workspace file for validity
    * @description validates the workspace and fills our member vars
    * @return {boolean}
    ###
  checkForValidWorkspaces: ->
    return true


  ###* Mandatory for Plugins. Returns a fully instanciated SettingsFile for Workspace Settings
   * @return {LSSettingsFileObject|null}
   ###
  getWorkspaceAndSchema: ->
    return this.workspaceFile

  ###* Mandatory for Plugins. Returns an empty settings template for workspace definition
    * @return {object}
    ###
  workSpaceTemplate: ->
    template =
      workSpaceType: this.name
      name: 'New PLUGIN Workspace'
      description: "My shiny new #{this.name} Workspace"
      favourite:false
      icon: this.icon
      folders: [
        {
          path:''
          prefix:''
        }
      ]
    return template

  ###* Mandatory for Plugins. Retuns the schema for workspace settings
   * @return {object}
   ###
  workSpaceSchema: ->
    schema=[
      {
        id: "name",
        label: "Workspace Name",
        subLabel: "Give Your new Workspace a name",
        component: "QInput",
        dense: true,
        rounded: true,
        standout: true,
        clearable:true,
        span: "100%"
      },
      {
        id: "description",
        label: "Short description",
        subLabel: "The short description will show up in the workspace list.",
        component: "QInput",
        dense: true,
        rounded: true,
        standout: true,
        clearable:true,
        span: "100%"
      },
      {
        id: "favourite",
        component: "QToggle",
        type: "checkbox",
        span: 1,
        dense: true,
        label: "Favourite",
        subLabel: "When checked, this will show up in the favourite sections in the workspace list",
        defaultValue: false
    }
      {
        label: "Included Folders",
        subLabel: "Enter your folders i the left column, and the prefix key of this folder in the right one. The prefix key is something like 'ics', 'pts' etc."
      },
      {
        id: "folders",
        component: "BlitzListForm",
        required: true,
        span:"100%"
        schema: [
          {
            id: "prefix",
            subLabel: "The file prefix used here like 'ics', 'pts'",
            label: "PrefixKey",
            component: "QInput",
            dense: true,
            rounded: true,
            standout: true,
            clearable:true,
            span:"20%",
            hint:""
          },
          {
            id: "path",
            label: "Path",
            subLabel: "An absolut file path. Use double backslash (\\) in windows.",
            component: "QInput",
            dense: true,
            rounded: true,
            standout: true,
            clearable:true,
            span:"80%",
            hint: "DoubleClick me for Folder Select Dialog",
            events: {
              dblclick: (evt,{rowData}) =>
                files = ComponentHub.AppBridge.openFolder()
                if files and files.length
                  rowData.path = files[0]

            }
          }

        ]
      }
    ]
    return schema

  ###* Mandatory for Plugins. Returns a template object for Plugin Settings
    * @return {object}
    ###
  pluginSettingsTemplate: ->
    template =
      pluginsettings:this.name

    return template


  ###* Mandatory for Plugins. Returns the Schema for Plugin Settings
    * @return {object}
    ###
  pluginSettingsSchema: ->
    schema= [
           {
            label: "Settings",
            subLabel: "This plugin has no general settings"
          },
        ]
    return schema

  ###* Mandatory for Plugins. Returns a fully initialized Plugin SettingsFile
   * @return {LSSettingsFileObject}
   ###
  getSettingsAndSchema: ->
    if this.settings?
      await ComponentHub.dbSetSettings this.name, this.settings
    else
      ComponentHub.dbSetSettings  this.name, @pluginSettingsTemplate()
    this.settings = await ComponentHub.dbGetSettings  this.name
    this.settingsFile = new LSSettingsFileObject this.name, this.settings , this.pluginSettingsSchema()
    this.settingsFile.displayName = "PLUGIN Settings"
    return this.settingsFile

  #endregion Workspace and Settings Handling
  #region EventHandlers FileTree

  ###*  Mandatory for Plugins. Ommitted when the RootDir Changed
    * @deprecated
    ###
  onFileTreeRootDirChanged: (folder) ->
    return
  ###* Mandatory for Plugins.  Ommitted when a new Record appers
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeNewRecord: (resource) ->

    return

  ###* Mandatory for Plugins. Ommitted when a  Record was moved or renamed
    * @param {FileSystemFile | PandoraReqFile} resource
    ###

  onFileTreeMoveRecord: (resource) ->

    return

  ###* Mandatory for Plugins. Ommitted when a  Record was deleted
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeDelRecord: (resource) ->

    return

  ###* Mandatory for Plugins. Ommitted when a  Record was modified
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeChangedRecord: (resource) ->

    return
  ###* Ommitted when the FileSystemWatcher is ready ###
  onFileTreeReady: ->
    return

  #endregion EventHandlers FileTree



