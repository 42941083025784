import {
  fileOpen,
  directoryOpen,
  fileSave,
  supported
} from 'browser-fs-access'
import {getFiles} from "./legacy"
import ComponentHub from "core/componentsHub.coffee"
#import { get, set } from 'idb-keyval'
export class FileManager
  constructor: (@config) ->
    @currentFiles = {}
    @initialized = false
    @permissionGranted = false
    @rootDirHandle=null
    @rootDirName = undefined

  ###
    Retrieves Handle from IndexedDB
    @method getHandle
    @return {null}
  ###
  getHandle: ->
    @rootDirHandle = await ComponentHub.dbGetConnectionData(this.config.workSpaceType, this.config.name, 'rootDirHandle')
    if @rootDirHandle
      @rootDirName = @rootDirHandle.name

  ###
    Sets Handle to IndexedDB
    @method setHandle
    @return {null}
  ###
  setHandle: ->
    await ComponentHub.dbSetConnectionData(this.config.workSpaceType, this.config.name,'rootDirHandle', @rootDirHandle)
    @getHandle()



  ###
    Calls a Dir Picker Checks Permissions and stores the new handle in idexdb
    Also sets our internal filelist
    @method setNewHandle
    @return {null}
  ###
  setNewHandle:  ->
    options=
      id: 'rootDirHandle'
      startIn: 'downloads'
    try
      ###* @ts-ignore ###
      @rootDirHandle = await window.showDirectoryPicker(options)
    catch error
      return false
    await @setHandle()
    @rootDirName = @rootDirHandle.name
    if not @permissionGranted
      @permissionGranted= await @verifyFullPermissions()
    if  @permissionGranted
      @setLocalfiles await getFiles(@rootDirHandle,true,@rootDirHandle.name,null)
    @updateToolBars()
    return true

  ###
    Wrapper for getHandle
    @method readStoredHandle
    @return {null}
  ###
  readStoredHandle: ->
    await @getHandle()

  ###
    Silently checks permissions
    @method checkPermission
    @return {null}
  ###
  checkPermission: ->
    options = {}
    options.mode = 'readwrite'
    return await @rootDirHandle.queryPermission(options) is 'granted'

  ###
    Checks permissions and asks user for them if not granted
    @method checkPermission
    @param {boolean} true if readwrite permissions are requested
    @return {boolean}
  ###
  verifyFullPermissions: (readWrite=true) ->
    options = {}
    @permissionGranted= false
    if readWrite
      options.mode = 'readwrite'
    if await @rootDirHandle.queryPermission(options) is 'granted'
      @permissionGranted = true
      return @permissionGranted
    if await @rootDirHandle .requestPermission(options) is 'granted'
      @permissionGranted = true
      return @permissionGranted
    return @permissionGranted

  ###
    Adds all files of given blob array to @current files.
    Also corrects absolute webkit Path to our relative path
    @method setLocalfiles
    @param {array} blobs returned from API
    @return {null}
  ###
  setLocalfiles: (blobs) ->
    @currentFiles = {}
    for blob in blobs
      @currentFiles[blob.webkitRelativePath.slice(this.rootDirHandle.name.length)] = blob
    @initialized = true

  ###
    Calls The maintoolbar if present to update himself
    @method updateToolBars
    @return {null}
  ###
  updateToolBars: ->
    # ComponentHub.tell "mainResourceActionBar" ,"setLocalDirProperties", @rootDirName , @initialized

  ###
   refreshes the local filestore
    @method refresh
    @return {null}
  ###
  refresh: ->

    if not @permissionGranted
      @permissionGranted= await @verifyFullPermissions()
    if @permissionGranted

      @setLocalfiles await getFiles(@rootDirHandle,true,@rootDirHandle.name,null)
    @updateToolBars()

  ###
    Saves given content to given file to the local filesystem
    if the file or one of the
    parent folders does not exist, it will be created
    @method saveLocalFile
    @param {string} filePath, the relative (our) filePath
    @param {any} content of the file
    @return {null}
  ###
  saveLocalFile: (filePath,content)->
    if !@initialized
      await @refresh()
    splittedPath = filePath.split('/')
    fileName = splittedPath[-1..][0]
    if @currentFiles.hasOwnProperty(filePath)
      handle =  @currentFiles[filePath].fileHandle
      #await fileSave(content, {fileName:fileName}, handle)
      writable = await handle.createWritable()
      await writable.write(content)
      await writable.close()
    else
      start = "/"
      lastDirHandle=null
      for dir in splittedPath[1...-1]
        start + dir
        if not @currentFiles.hasOwnProperty(start + dir)
          dirToSave =  start[0..-2]
          if dirToSave.length is 0
            dirToSave = '/'
          lastDirHandle = await @createLocalFolder(dirToSave, dir,lastDirHandle)
          if not lastDirHandle
            console.warn "File", fileName, "could not be saved! Parent Directory could not be created"
            return
        start = start + dir + "/"
      dirToSave =  start[0..-2] #remove trailing slash
      if @currentFiles.hasOwnProperty(dirToSave)
        handle = await @createLocalFile(dirToSave,fileName)
        if handle
          try
            #await fileSave(content, {fileName:fileName}, handle)
            writable = await handle.createWritable()
            await writable.write(content)
            await writable.close()
          catch error
            console.warn "File", fileName, "could not be saved!", error
      await @refresh()

  ###
    Creates a local file with given name to given path
    the parent folders must exist!
    @method createLocalFile
    @param {string} filePath, the relative (our) filePath
    @param {string} filename
    @return {null}
  ###
  createLocalFile: (path,name) ->
    dirhandle = @currentFiles[path].directoryHandle
    try
      newFileHandle = await dirhandle.getFileHandle(name, { create: true })
    catch error
      console.warn "createLocalFile",path,name, error
      return null
    return newFileHandle

  ###
    Creates a local folder with given name to given path
    the parent folders must exist!
    @method createLocalFolder
    @param {string} filePath, the relative (our) filePath
    @param {string} foldername
    @param {handle} lastDirHandle if given this should be the handle of the parent dir
    @return {handle} The handle of the newly created folder
  ###
  createLocalFolder: (path,name,lastDirHandle=null) ->
    dirhandle = lastDirHandle
    ###* @ts-ignore ###
    if dirhandle is null
      if path is '/'
        dirhandle = @rootDirHandle
      else
        dirhandle = @currentFiles[path].directoryHandle
    try
      newDirectoryHandle = await dirhandle.getDirectoryHandle(name, {create: true})
    catch error
      console.warn "createLocalFolder",path,name,error
      return null
    @currentFiles[path + '/' + name] = {directoryHandle:newDirectoryHandle,name:name,kind:'directory',webkitRelativePath:@rootDirName + path + '/' + name}
    return newDirectoryHandle

