# # MetaData
export class MetaData
  ###
  Base Class one Metadata item
  Metadata are attributes of [ImproveResources ](ImproveResource.html)
  @constructor
  @param {Object} rawData as returned from REST API
  @returns {Metadata}
  ###
  constructor: (rawData) ->
    this.displayValue = null
    this.valueMaps = null
    this.inheritance = 'None'
    this.descriptorType = ''
    this.dateValue = 0
    this.textValue =''
    this.numValue =0
    this.lovText=''
    this.inheritToChilds=false
    this.inheritedFromParent = false
    this.update rawData

  ###
  updates this instance with fresh data
  @method update
  @param {String} rawData as returned from REST API
  @return {null} null
  ###
  update: (rawData)->
    rawData && Object.assign this, rawData
    this.generateDisplayValue()
    this.generateInheritanceValue()


  ###
  Depending on the datatype of this MetaData, the fields i the REST Response have diffenrent names
  This method maps the corresponding field on the field displayValue.
  The value valueMaps stores the name of the datafield in the Object returned by the API
  @method generateDisplayValue
  @return {null} null
  ###
  generateDisplayValue: () ->
    switch this.descriptorType
      when "DATE"
        this.displayValue = new Date(parseInt(this.dateValue)).toLocaleString()
        this.valueMaps = 'dateValue'
      when "TEXT"
        this.displayValue = this.textValue
        this.valueMaps = 'textValue'
      when "NUMBER"
        this.displayValue = this.numValue
        this.valueMaps = 'numValue'
      when "LOV"
        this.displayValue = this.lovText
        this.valueMaps = 'lovText'
      else
        this.displayValue = '-'
        this.valueMaps = null


  ###
  sets the property inheritance with a displayable String giving the user information
  how inheritance works on this MetaData Item
  @method generateDisplayValue
  @return {null} null
  ###
  generateInheritanceValue: () ->
    if this.inheritToChilds  and this.inheritedFromParent
      this.inheritance = "Both"
    else if this.inheritToChilds  and not this.inheritedFromParent
      this.inheritance = "Inherit"
    else if not this.inheritToChilds  and this.inheritedFromParent
      this.inheritance = "Inherited"
    else
      this.inheritance = "None"

