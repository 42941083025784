
import { extend , uid} from 'quasar'


class TabState
  constructor: ->
    this.renderMode = false


export class LayoutNode
  #region Getters
  ###* virtual Field (Getter) returns the cssVars
    Do we have ChildPanes ?
    * @name LayoutNode#hasChildPanes
    * @type {boolean}
    ###
  Object.defineProperty this::, "hasChildPanes",
    get: ->
      return Object.keys(this.childPanes).length > 0
  ###* virtual Field (Getter)
    * Do we have tabs ?
    * @name LayoutNode#tabs
    * @type {boolean}
    ###
  Object.defineProperty this::, "hasTabs",
    get: ->
      return Object.keys(this.tabs).length > 0

  ###* virtual Field (Getter)
    * CurrentTabState ?
    * @name LayoutNode#tabs
    * @type {object}
    ###
  Object.defineProperty this::, "currentTabState",
    get: ->
      if Object.keys(this.tabs).length > 0
        return this.tabs[this.currentTab]
      return null

  constructor: (isSplitter=false) ->
    this.isSplitter=isSplitter
    this.type="node"
    this.id = uid()
    this.isHorizontal=false
    this.childPanes={}
    this.tabs={}
    ###since we do not know the paneid at this point its null per default.
    it will be set as soon a contenttab is created inside. Look into the setup in contenttab.vue
    ###
    #
    ###* @type {string | null} ###
    this.paneId = null
    this.currentTab=''
    this.isRoot = false
    #
    ###* @type {object | null} ###
    this.parent = null
    this.path= this.id
    this.root=this


  ###*
   * Splits the current pane. If no rowkey of the records is given, the current slected will be splitted
   * @param {boolean} horizontal - If the splitter should be horizontal, means the panes are above each other
   * @param {string|null} rowKey
   * @return {*}
   ###
  splitMe: (horizontal=false,rowKey = null, sourcePaneId = null, removeSourceTab=false) ->
    if !this.isSplitter
      this.childPanes = {}
      rightNode = new LayoutNode()
      #rightNode.isHorizontal= horizontal
      unless rowKey?
        rowKey = this.currentTab
      rightNode.addTab rowKey
      rightNode.currentTab = rowKey
      if removeSourceTab
        if this.tabs.hasOwnProperty(rowKey)
          this.deleteTab rowKey
          if Object.keys(this.tabs).length > 0
            this.currentTab = Object.keys(this.tabs)[0]
        else
          sourcePane = this.root.findPaneId sourcePaneId
          if sourcePane?
            sourcePane.deleteTab rowKey
            if Object.keys(sourcePane.tabs).length > 0
              sourcePane.currentTab = Object.keys(sourcePane.tabs)[0]

      if this.isHorizontal isnt horizontal
        leftNode = new LayoutNode()

        leftNode.tabs = extend(true,{},this.tabs)
        leftNode.currentTab = this.currentTab
        this.tabs={}
        leftNode.isHorizontal = horizontal
        rightNode.isHorizontal = horizontal
        this.addChild leftNode
        this.addChild rightNode
        this.isHorizontal = horizontal
        this.isSplitter=true
      else

        this.parent.addChild rightNode
      this.root.parent.activePanel = rightNode

      this.root.parent.saveLayout()



  ###*
   * moves the given tab to another pane
   * @param {string} tabId
   * @param {object} targetNode
   ###
  moveTab: (tabId, targetNode) ->
    if this.tabs.hasOwnProperty(tabId)
      targetNode.tabs[tabId] = this.tabs[tabId]
      this.deleteTab tabId
      targetNode.currentTab = tabId
    this.root.parent.saveLayout()

  ###*
   * copies the given tab to another pane
   * @param {string} tabId
   * @param {object} targetNode
   ###
  copyTab: (tabId, targetNode) ->
    if this.tabs.hasOwnProperty(tabId)
      targetNode.tabs[tabId] = this.tabs[tabId]
      targetNode.currentTab = tabId
    this.root.parent.saveLayout()

  ###*
   * adds a new pane to this node
   * @param {object} node
   ###
  addChild: (node) ->
    node.parent = this
    node.path = "#{this.path}/#{node.id}"
    node.root=this.root
    this.childPanes[node.id] = node
    this.root.parent.activePanel = node
    console.log "After add :", this.root
    this.root.parent.saveLayout()

  ###*
   * searches down the hierachy to find the given node with our internal id
   * @param {string} id
   * @return {LayoutNode | null}
   ###
  findId: (id) ->
    if this.id is id
      return this
    for child of this.childPanes
      childPane = this.childPanes[child].findId id
      if childPane?
        return childPane
    return null

  ###*
   * searches down the hierachy to find the given node with the pane id from splitPanes returns null if not found
   * @param {string} id
   * @return {LayoutNode|null}
   ###
  findPaneId: (id) ->
    if this.paneId is id
      return this
    for child of this.childPanes
      childPane = this.childPanes[child].findPaneId id
      if childPane?
        return childPane
    return null

  deleteAllTabsByTabId: (id) ->
    this.deleteTab id
    for child of this.childPanes
      this.childPanes[child].deleteAllTabsByTabId id


  ###*
   * Deletes a tab and selects another tab with this group of tabs. If the lat tab was deleted
   * the node is requested to delete the whole pane
   * @param {string} id
   ###
  deleteTab: (id) ->
    if this.tabs.hasOwnProperty(id)
      tabs = Object.keys(this.tabs)
      index = tabs.indexOf(id)
      if tabs.length > 0
        if index is 0
          if tabs.length > 1
            newIno = tabs[1]
        else
          newIno =  tabs[index-1]
      delete this.tabs[id]
      tabs = Object.keys(this.tabs)
      if tabs.length > 0
        setTimeout () =>
          this.currentTab = newIno
        ,500
      else
        if !this.isRoot
          # if we are not root and do not have any tabs anymore (the reason why we are here)
          # and do not have any childPanes, tell out parent to remove us
          deleteMe = false
          if this.childPanes?
            if Object.keys(this.childPanes).length is 0
              deleteMe = true
          else
            deleteMe = true
          if deleteMe
            this.parent.deletePanel this.id
        else
          this.root.parent.activePanel = this
      this.root.parent.saveLayout()
      return true
    return false

  ###*
   * Deletes the given pane/panel if the was the last panel in this node
   * the parent node is requested to delete the whole node
   * @param {string} id
   ###
  deletePanel: (id) ->
    deleteMe = false
    if this.childPanes.hasOwnProperty(id)
      # delete the requested panel
      delete this.childPanes[id]
    # and now check if we still have tabs in case this was the last panel
    if Object.keys(this.childPanes).length is 0
      if Object.keys(this.tabs).length is 0
        deleteMe = true
    if deleteMe and !this.isRoot
      this.parent.deletePanel this.id
    else
      this.root.parent.activePanel = this


  ###*
   * Adds a new tab to the current group and sets it active. id has to be a rowId from the fileSystemTree
   * this is a resourceId in case of improve rEST or an inode number of a file
   * @param {string|object} id
  ###
  addTab: (id) ->
    if this.isRoot
      if Object.keys(this.childPanes).length is 0
        firstNode= new LayoutNode()
        firstNode.tabs[id] = new TabState()
        firstNode.currentTab = id
        this.addChild firstNode

    else
      this.tabs[id] = new TabState()
      this.currentTab = id
    if this.root.parent?
      this.root.parent.saveLayout()


  ###*
    * Gets a serializable structure of this node
    * @return {object}
    ###
  getStructure: ->
    myInfo =
      childPanes: {}
      tabs: this.tabs
      isHorizontal: this.isHorizontal
    for id of this.childPanes
      myInfo.childPanes[id] = this.childPanes[id].getStructure()
    return myInfo

export class LayoutTree
  #region Getters
  ###* virtual Field (Getter)
    * Do we have ChildPanes ?
    * @name LayoutTree#hasChildPanes
    * @type {boolean}
    ###
  Object.defineProperty this::, "hasChildPanes",
    get: ->
      return Object.keys(this.root.childPanes).length > 0
  #region Getters
  ###* virtual Field (Getter)
    * Do we have tabs ?
    * @name LayoutTree#tabs
    * @type {boolean}
    ###
  Object.defineProperty this::, "hasTabs",
    get: ->
      return Object.keys(this.root.tabs).length > 0
  ###* virtual Field (Getter)
    * Do we have tabs ?
    * @name LayoutTree#activePanel
    * @type {boolean}
    ###
  Object.defineProperty this::, "activePanel",
    get: ->
      unless this._activePanel?
        this._activePanel=this.root
      return this._activePanel
    set: (val) ->
      this._activePanel = val

  constructor: ->
    this.type="tree"
    this.root = new LayoutNode(true)
    this.root.parent = this
    this.root.isRoot = true
    this._activePanel = this.root



  ###*
   * Finds the a pane by given internal id and sets it active
   * @param {string} id
   ###
  setActivePanelById: (id) ->
    this.activePanel = this.getChildById id
  ###*
   * Finds the a pane by given splitpanes id and sets it active
   * @param {string} id
   ###
  setActivePanelByPaneId: (id) ->
    this.activePanel = this.getChildByPaneId id

  ###*
   * Finds the a pane by given interal id
   * @param {string} id
   * @return {LayoutNode|null}
   ###
  getChildById: (id) ->
    return this.root.findId id

  ###*
   * Finds the a pane by given splitpanes id
   * @param {string} id
   * @return {LayoutNode | null}
   ###
  getChildByPaneId: (id) ->
    return this.root.findPaneId id

  saveLayout: ->
    return this.root.getStructure()

  resetLayout: ->
    for child of this.root.childPanes
      this.root.deletePanel child

  moveNodeById: (nodeId,newParentId) ->
    node = this.getChildById nodeId
    if node?
      newParent = this.getChildById newParentId
      if newParent?
        delete node.parent.childPanes[ nodeId]
        newParent.addChild node

  moveNodeByPaneId: (nodePaneId,newParentPaneId) ->
    node = this.getChildByPaneId nodePaneId
    if node?
      newParent = this.getChildByPaneId newParentPaneId
      if newParent?
        delete node.parent.childPanes[node.id]
        newParent.addChild node

  deleteNodeById: (nodeId) ->
    node = this.getChildById nodeId
    if node?
      delete node.parent.childPanes[ nodeId]

  deleteNodeByPaneId: (nodePaneId) ->
    node = this.getChildByPaneId nodePaneId
    if node?
      delete node.parent.childPanes[ node.id]








