# All so far known types of improve resources
export resourceTypes =
  Folder:
    folderish: true
    icon: 'mdi-folder-open-outline'
  File:
    folderish: false
    icon: 'mdi-file-document-outline'
  Review:
    folderish: true
    icon:  'mdi-glasses'
  Step:
    folderish: true
    icon: 'mdi-stairs'
  'Analysis Tree':
    folderish: true
    icon: 'mdi-family-tree'
    
