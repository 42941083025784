import {markRaw} from 'vue'
import CompositionBase from 'core/CompositionBase.coffee'
import {FileSystemFile} from 'components/FileSystemBrowser/FileSystemFile.coffee'
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import path from 'path'




###* Props definition
  * @typedef {Object}  EditorngProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  * @property {FileSystemFile} file - The file to deal with
  ###


###*
* @augments CompositionBase
###
export class Editorng extends CompositionBase
  ###* virtual Field (Getter) tells whether the map is on or off*
    * @name Editorng#mapIsOn
    * @type {boolean} mapIsOn
    ###
  Object.defineProperty this::, "mapIsOn",
    get: ->
      if this.editor?
        return this.mapEnabled
      return false

    enumerable: true
    configurable: true
  #
  ###*
    * @param {Object} options
    * @property {options.EditorngProps} props
    * @property {options.Function} emit
    * @property {options.object} refs
    * @property {options.object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.props = options.props
    this.emit = options.emit
    this.editorType="monaco"
    this.isUtil=false
    #
     ###*
      * @typeof {FileSystemFile}
      ###
    this.file = this.props.file
    this.compareFile = this.props.compareWith
    this.wrapper = null
    #
    ###*
      * @type {monaco.editor.IStandaloneCodeEditor | null}
      ###
    this.editor = null
    #
    ###*
      * @type {monaco.editor.IStandaloneDiffEditor | null}
      ###
    this.diffEditor = null
    this.mapEnabled = true
    this.decorations = []
    this.commentIncrementor = 0
    this.lang = "plaintext"
    this.commentWidgets=[]



  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    return [
      {
        name: "Save"
        disabled: this.editor is null
        highlight: false
        hidden: this.hideSaveButton()
        icon: "o_save"
        tooltip: "Save"
        click:  () =>
          this.saveFile(this.editor)
      },
      {
        name: "EditorToggleMap"
        disabled: this.editor is null
        highlight: this.mapIsOn
        hidden: false
        icon: if this.mapIsOn then 'map' else "o_map"
        tooltip: "Toggle Minimap"
        click: () =>
          this.toggleMinimap()
      }
    ]

  ###*
   * @description Is called from mounted instanciates the Editor
  ###
  initMonaco: () ->
    this.wrapper = document.getElementById(this.props.instancename)
    editor =  monaco.editor.create(this.wrapper, this.getEditorConfiguration())
    editor.onDidBlurEditorWidget (evt) => this.editorBlurred(editor)
    editor.onKeyDown (evt) => this.editorKeyDown(editor,evt)
    editor.onMouseUp (evt) => this.editorMouseUp(editor,evt)
    this.editor =  markRaw(editor)



  toggleMinimap: ->
    if this.editor?
      this.mapEnabled = !this.editor.getOption(monaco.editor.EditorOptions.minimap.id).enabled
      this.editor.updateOptions({minimap: {enabled: this.mapEnabled} })

  ###*
   * @description Creates the initial Configuration for the editor
   * @return {object}
  ###
  getEditorConfiguration: ->
    model = null
    theme = if this.quasar.dark.isActive then 'vs-dark' else 'vs'
    if this.file?
      if this.file.hasOwnProperty('diagnosticSchema')
        diagnosticSchema = this.file.diagnosticSchema
        diagnosticFileMatch = this.file.diagnosticFileMatch
        model = @setModel 'json', diagnosticFileMatch, this.file.getContent()
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions(diagnosticSchema)
      else
        model = @setModel this.getMonacoLanguage(this.file), this.file.path, this.file.getContent()
    else
      model = @setModel "plaintext", "/emptyFile", ""
    options =
      model:model,
      automaticLayout: true,
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      theme: theme
      # Next wo lines removed due bug: https://github.com/microsoft/monaco-editor/issues/3013
      bracketPairColorization:true
      guides: {bracketPairs:true}
      glyphMargin: true
    return options

  ###*
   * @description Registers a new LanguageModel if not alreay present
   * @param {string} lang
   * @param {string} path
   * @param {string} content
   * @return {monaco.editor.ITextModel}
  ###
  setModel: (lang, path, content) ->
    # RESTAPI returns a JSON Object in case the file is a valid JSON File, we need to change to a string
    if typeof content is "object"
      content = JSON.stringify(content,null,4)
    modelUri = monaco.Uri.parse(path)
    model = null
    for mdl in monaco.editor.getModels()
      if mdl.uri.path is modelUri.path
        mdl.setValue(content.toString())
        model = markRaw(mdl)
        break
    unless model?
      model = monaco.editor.createModel(content.toString(), lang, modelUri)
    return model


  ###*
   * @description Gets a list of all available Language modes from monaco
   * @param {any} file
   * @return {string}
  ###
  getMonacoLanguage: (file)->
    lang = "plaintext"
    if file?
      ext = path.extname(file.name)
      ext_lc = ext.toLowerCase()
      entryList = (entry for entry in monaco.languages.getLanguages() when entry.extensions? and  (entry.extensions.indexOf(ext) >= 0 or entry.extensions.indexOf(ext_lc) >= 0))
      if entryList.length
        lang = entryList[0].id
    this.lang=lang
    return lang

  changeFile: (newFile) ->
    if newFile?
      this.file = newFile
      await this.file.getInitContent()
      this.file.currentEditor = this
      if this.compareFile?
        this.changeCompareWith this.compareFile
      else
        if this.editor?
          this.editor && this.editor.dispose()
        if this.diffEditor?
          this.diffEditor && this.diffEditor.dispose()
        this.initMonaco()
    return

  changeCompareWith: (newComparement) ->
    if newComparement?
      if this.editor?
        this.editor && this.editor.dispose()
        this.editor=null
      if this.diffEditor?
        this.diffEditor && this.diffEditor.dispose()
        this.diffEditor=null
      this.wrapper = document.getElementById(this.props.instancename)
      langorig = this.getMonacoLanguage(this.file)
      # RESTAPI returns a JSON Object in case the file is a valid JSON File, we need to change to a string
      fileContent = this.file.getContent()
      if typeof fileContent is "object"
        fileContent = JSON.stringify(fileContent,null,4)
      model = monaco.editor.createModel(fileContent,langorig)
      this.compareFile = newComparement
      await this.compareFile.getInitContent()
      langcomp = this.getMonacoLanguage( this.compareFile)
      # RESTAPI returns a JSON Object in case the file is a valid JSON File, we need to change to a string
      cFileContent = this.compareFile.getContent()
      if typeof cFileContent is "object"
        cFileContent = JSON.stringify(cFileContent,null,4)
      cModel = monaco.editor.createModel(cFileContent,langcomp)
      diffEditor = monaco.editor.createDiffEditor(this.wrapper, {enableSplitViewResizing: false})
      diffEditor.setModel({original: model,	modified: cModel})
      this.diffEditor =  markRaw(diffEditor)
    else
      if this.diffEditor?
        this.diffEditor && this.diffEditor.dispose()
        this.diffEditor=null
      this.compareFile = null
      this.changeFile(this.file)


  darkSelector: (isDark) ->
    if this.editor?
      if isDark
        monaco.editor.setTheme 'vs-dark'
      else
        monaco.editor.setTheme  'vs'

  editorBlurred: (me) ->
    # No Autosave when working in Improve
    if !this.file.hasOwnProperty("iWebType")
      @saveFile me
    return

  editorKeyDown: (me,evt) ->
    if (evt.ctrlKey or evt.metaKey) and evt.code is "KeyS"
      @saveFile me
    return

  editorMouseUp: (me,evt) ->
    isGutter = evt.target.type is 3 or evt.target.type is 4
    if isGutter
      withinLines = !evt.target.detail.isAfterLines
      if withinLines
        lineNumber = evt.target.position.lineNumber
        if this.file.hasOwnProperty("iWebContext")
          if this.file.iWebContext is "ImproveReview"
            if this.file.isRevision
              ###* @ts-ignore ###
              this.ComponentHub.$quasar.notify({
                message: 'You cannot set comments in old revisions',
                color: 'red'
                position: 'center'
              })
              return
            ###* @ts-ignore ###
            this.ComponentHub.$quasar.dialog({
              title: 'Enter Line Comment',
              message: "Enter a comment for line #{lineNumber}",
              prompt: {
                model: '',
                type: 'text'
              },
              cancel: true,
              persistent: true
            })
            .onOk (data) =>
              prefix="$"
              sendText="#{prefix}#{lineNumber} #{data}"
              chatId = "commentchat" + this.file.resourceId
              this.ComponentHub[chatId].addComment(null,sendText)


        this.emit('gutterClick',lineNumber)


  addComment: (comment, line,user,date) ->
    if this.editor?

      msg = document.createElement("div")
      icon = msg.appendChild(document.createElement("span"))
      icon.innerHTML = "🔰"
      icon.className = "improve-comment-icon"
      box = msg.appendChild(document.createElement("span"))
      box.innerText = "#{date} by #{user}: #{comment}"
      box.className = "clens"
      msg.className = "improve-comment"
      this.editor.changeViewZones( (changeAccessor) =>
        viewZoneId = changeAccessor.addZone({
          afterLineNumber: line-1,
          heightInLines: 1,
          domNode: msg}
        )
        this.commentWidgets.push viewZoneId
      )

  clearAllComments: (callBack)->
    if this.editor?
      this.editor.changeViewZones (changeAccessor) =>
        for id in this.commentWidgets
          changeAccessor.removeZone id
        callBack()

  hideSaveButton: ->
    if this.file?
      if this.file.hasOwnProperty('iWebContext')
        if this.file.iWebContext is 'ImproveReview'
          return true
      return false
    return true

  ###* Saves the current file
      * @method saveFile
      * @param {object} me - The Editor  instance
      ###
  saveFile: (me) ->
    if this.file.hasOwnProperty('iWebContext')
      if this.file.iWebContext is 'ImproveReview'
        ###* @ts-ignore ###
        this.ComponentHub.$quasar.dialog({
          title: 'ReadOnly',
          message: "Objects within a review can't be saved!"}
        )
        return
    if @file? and me?
      @file.content = me.getValue()
      @file.save()
      this.emit('saved', me.getValue())



  mounted: ->
    super()
    this.file = this.props.file

  unmounted: ->
    super()
    this.editor && this.editor.dispose()
    if this.file?
      this.file.currentEditor = null
