# # Improve Step
import ComponentHub from "core/componentsHub.coffee"
import {ImproveResource} from './ImproveResource.coffee'
import {ImproveProcess} from './ImproveProcess.coffee'
import {apiGetStepProcesses, apiGetRunserver,apiGetProcessRuns} from 'apis/improveREST/rawImprove.coffee'



export class ImproveStep extends ImproveResource
  ###
  Base Class representing a ImproveStep extends [ImproveResource](ImproveResource.html)
  Usually contains [processes](processes.html)
  @constructor
  @param {Object} rawData as returned from REST API
  ###
  constructor: (rawData) ->
    super rawData
    this.iWebType = 'ImproveStep'
    this.iWebContext = 'ImproveResource'
    this.processes = []



  ###
    Loads also our relatedObjects like Metadata and revisions
    ###
  onDeepLoad:  ->
    await this.getRevisionHistory()
    await this.getMetadata()
    if@processes.length is 0
      await @getProcesses()
    return

  getProcesses: ->
    result = await ComponentHub.activeServer.doQuery apiGetStepProcesses this.resourceId, this.revisionId
    processes = []
    for proc in result.data
      ip = new ImproveProcess proc,this.resourceId, this.revisionId
      await ip.loadRuns()
      processes.push ip
    @processes = processes


