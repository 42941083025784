import {markRaw, toRaw, reactive} from 'vue'
import { LocalStorage, SessionStorage,  useQuasar  } from 'quasar'
import {VERSION} from "../version.coffee"
import {BB_BUILD} from "../pipeline.coffee"
import {
  initStore,
  getSettings,
  getWorkspace,
  getIndex,
  setSettings,
  setWorkspaceAndRename,
  setWorkspace,
  setIndex,
  deleteWorkspace,
  getAllWorkspacesFromPlugin,
  getAllWorkspaces,
  getConnectionData,
  setConnectionData,
  loginAllWorkspaces,
  logoutAllWorkspaces,
  getGlobalPwdForUser
  } from './dbManager.coffee'
import MiniSearch from 'minisearch'
import {LayoutTree,LayoutNode} from 'components/contentPane/layoutTree.coffee'


###*
* @typedef {Object.<string, any>} ComponentHub
###

###*
* @typedef {Object} SearchIndexModel
* @property {string} idField - The name of the unique key field
* @property {string[]} fields - The names of all fields which can be searched
* @property {string[]} storeFields - The names of all fields which can be included (and therefore displayed) in search results
###

#
###*
 * @type {SearchIndexModel}
 ###
defaultIndexerModel =
  idField: 'ino'
  fields: ['name', 'content','cachedPath'] # fields to index for full-text search
  storeFields: ['name', 'cachedPath'] # fields to return with search result

#
  ###*
  * @method openActiveIndex
  * @description Loads the Index from disk
  * @return {boolean} true when an indexfile was present
  ###
openActiveIndex = ->
  if ch.activePluginName?
    index = await getIndex ch.activePluginName,ch.activeWorkspace.name
    if index isnt '{}'
      try
        ch.indexer = MiniSearch.loadJSON(index, ch.activePlugin.indexerModel)
        return true
      catch e
        console.warn "Index for ",ch.activePluginName,ch.activeWorkspace.name, "could not be loaded"
        return false
    return false
  return false
#
  ###*
  * @description Stores the Index to disk
  * @method storeActiveIndex
  ###
storeActiveIndex = ->
  if ch.activePluginName?
    setIndex  ch.activePluginName,ch.activeWorkspace.name ,JSON.stringify(ch.indexer)
  return

#
  ###*
  * @method resetActiveIndex
  * @description Resets the Index Name
  ###
resetActiveIndex = ->
  indexerModel = defaultIndexerModel
  if ch.activePlugin and ch.activePlugin.indexerModel?
    indexerModel = ch.activePlugin.indexerModel
  ch.indexer = new MiniSearch(indexerModel)
  storeActiveIndex()
  return

#
  ###*
  * @method loadGlobalSettings
  * @description Loads the globalSettings from LS and creates them if not present
  * @return {object}
  ###
loadGlobalSettings = ->
  initStore 'globalApp'
  settings = await getSettings 'globalApp'
  if !settings.hasOwnProperty("darkMode")
    setSettings 'globalApp', globalSettingsTemplate
  ch.globalAppSettings=  await getSettings 'globalApp'


#
  ###*
  * @method saveGlobalSettings
  * @description Saves the global Settings back based on given settings
  *   If settings is null, just the current settings are written back.
  *   if we are in electron mode, they also are passed back to the main process to update menus and persist it to disk
  *   so they are available at next startup
  * @param {object=} settings
  ###
saveGlobalSettings = (settings) ->
  unless settings?
    settings = ch.globalAppSettings
  setSettings 'globalApp', settings
  ch.globalAppSettings = settings
  if ch.$quasar?
    ###* @ts-ignore ###
    ch.$quasar.dark.set(settings["darkMode"])
  if process.env.MODE is "electron" #globalThis.appBridge?
    globalThis.appBridge.setSettings(toRaw(settings))
  return


globalSettingsTemplate =
    globalAppSettings: true,
    darkMode: "auto",
    lastWorkSpaces: [ ],
    devTools: true,
    tokenCounter:true

globalSettingsSchema = [
    {
        id: "darkMode",
        component: "QSelect",
        span: 1,
        dense: true,
        label: "Darkmode",
        subLabel: "Set the default Theme for the Application. OS dependent means, it always chooses the current mode of the system. But you can always switch with the moon and sun icon in the main Navigation Bar",
        options: [
            "auto",
            true,
            false
        ]
    },
    {
        id: "tokenCounter",
        component: "QToggle",
        type: "checkbox",
        span: 1,
        dense: true,
        label: "Show Token Lifetime",
        subLabel: "When set, the remaining token lifetime is shown in the statusbar",
        defaultValue: true
    },
    {
        id: "devTools",
        component: "QToggle",
        type: "checkbox",
        span: 1,
        dense: true,
        label: "Dev Tools",
        subLabel: "Enable the debugging Tools inside the Application. This will only be available for Testing and developing. This setting will be removed on production builds.",
        defaultValue: false
    },
    {
        id: "globalPasswords",
        component: "QToggle",
        type: "checkbox",
        span: 1,
        dense: true,
        label: "Save Passwords per Host",
        subLabel: "If this setting is true, passwords will be stored globally per host and user, regardless of individual workspace settings. Disable this setting if you do not want to store passwords at all.",
        defaultValue: true
    }
]

globalJsonDiagnosticFileMatch="a:/scinteco/global.json"
globalJsonDiagnosticSchema =
  validate: true
  schemas: [
    {
    uri: 'http://scinteco/global-schema.json', # id of the  schema
    fileMatch: [globalJsonDiagnosticFileMatch], # associate with our model
    schema:
      type: 'object',
      properties:
        darkMode:
          enum: [true, false, 'auto']
        devTools:
          enum: [true, false]
        tokenCounter:
          enum: [true, false]
        globalPasswords:
          enum: [true, false]
  }

  ]


openNewTabByRowKey = (id) ->
  if ch.hasOwnProperty("FileSystemTree")
    node = ch.FileSystemTree.getResource id
    if node?
      rowKey = ch.FileSystemTree.rowKey
      if not node.isFolderish or node.hasOwnProperty("resourceId")
        ch.layoutTree.activePanel.addTab(node[rowKey])
    else
      ###* @ts-ignore###
      ch.$quasar.notify({
        message: "This resource has not been loaded yet. Please open first it's parent in the left tree",
        color: "red",
        position: 'center'
      })


isActivePanelId = (id) ->
  return ch.layoutTree.activePanel.paneId is id

isActiveResource = (ino,id) ->
  return ch.layoutTree.activePanel.paneId is id and ino is  ch.layoutTree.activePanel.currentTab

resetLayout = ->
  ch.layoutTree.resetLayout()

delTreeRecord = (resourceId) ->
  if ch.hasOwnProperty("FileSystemTree")
    ch.FileSystemTree.onDelRecord resourceId
    ch.layoutTree.root.deleteAllTabsByTabId resourceId
    ch.storeActiveIndex()

refreshFolder = (resourceId) ->
  if ch.hasOwnProperty("FileSystemTree")
    myFolder = ch.FileSystemTree.getResource resourceId
    if myFolder?
      await myFolder.onExpand()

getResourceFromTree = (resourceId) ->
  if ch.hasOwnProperty("FileSystemTree")
    return ch.FileSystemTree.getResource resourceId
  return null

ch =
  aceOfSpades:'🂡'  # internal safe delimiter,   lemmy ;)
  isDebugVersion:false
  openNodes:{}
  layoutTree: reactive(new LayoutTree())
  startFromUrl:''
  isActivePanelId: isActivePanelId
  isActiveResource: isActiveResource
  activeContainerId:null
  activeContainer:null
  resetLayout:resetLayout
  activeMainPanel:0
  allStoredWorkSpaces: []
  LocalStorage: LocalStorage
  SessionStorage : SessionStorage
  isDesktop: if process.env.MODE is 'electron' then true else false
  indexer: new MiniSearch(defaultIndexerModel)
  FS: globalThis.improveFS
  AppBridge: globalThis.appBridge
  ###* @type {Object.<string,object>} ###
  loadedPlugins:{}
  ###* @type {Object.<string,object>} ###
  loadedPluginComponents:{}
  ###* @type {object | null} ###
  activeWorkspace: null
  ###* @type {object } ###
  activePlugin: null
  ###* @type {string | null} ###
  activePluginName: null
  ###* @type {string | null} ###
  activeindexFile: null
  globalJsonDiagnosticSchema: markRaw(globalJsonDiagnosticSchema)
  globalJsonDiagnosticFileMatch:globalJsonDiagnosticFileMatch
  globalSettingsTemplate:globalSettingsTemplate
  globalSettingsSchema:globalSettingsSchema
  openActiveIndex:openActiveIndex
  storeActiveIndex:storeActiveIndex
  resetActiveIndex:resetActiveIndex
  globalAppSettings: {}
  saveGlobalSettings:saveGlobalSettings
  $quasar:null
  loadGlobalSettings:loadGlobalSettings
  dbInitStore:initStore
  dbGetSettings:getSettings
  dbGetWorkspace:getWorkspace
  dbGetIndex:getIndex
  dbSetSettings:setSettings
  dbSetWorkspace:setWorkspace
  dbSetIndex:setIndex
  dbDeleteWorkspace:deleteWorkspace
  dbSetWorkspaceAndRename:setWorkspaceAndRename
  dbGetAllWorkspacesFromPlugin:getAllWorkspacesFromPlugin
  dbgetAllWorkspaces:getAllWorkspaces
  dbGetConnectionData:getConnectionData,
  dbSetConnectionData:setConnectionData,
  dbLogoutAllWorkspaces:logoutAllWorkspaces,
  dbLoginAllWorkspaces:loginAllWorkspaces,
  dbGetGlobalPwdForUser:getGlobalPwdForUser
  openNewTabByRowKey:openNewTabByRowKey
  refreshFolder:refreshFolder
  delTreeRecord:delTreeRecord
  getResourceFromTree:getResourceFromTree
  appMode:process.env.MODE
  appVersion: VERSION
  appBuild: BB_BUILD

export default ch
