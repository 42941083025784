# # Improve Review
import ComponentHub from "core/componentsHub.coffee"
import {ImproveResource} from './ImproveResource.coffee'
import {ReviewEntry} from './reviewEntry.coffee'
import getImproveClass from "components/ImproveBrowser/ImproveClasses.coffee"
import {depthTreeSort} from 'components/FileSystemBrowser/treesorting.coffee'
import {apiGetReviewEntries, apiNewResourceEntries, apiGetReviewComments,apiGetReviewDetails,apiGetResource,apiGetSubTree,apiGetSubTreeWithMeta,apiResourceGetChildSteps} from 'apis/improveREST/rawImprove.coffee'



export class ImproveReview extends ImproveResource
  ###* virtual Field (Getter)
    * @name ImproveReview#realEntries
    * @type {boolean}
    ###
  Object.defineProperty this::, "realEntries",
    get: ->
      return (entry for entry in this.entryRecs when !entry.virtual)
    enumerable: true
    configurable: true
  ###* virtual Field (Getter)
    * @name ImproveReview#userIsReviewer
    * @type {boolean}
    ###
  Object.defineProperty this::, "userIsReviewer",
    get: ->
      try
        for reviewer in this.reviewers
          if ComponentHub.activeServer.userdata.username is reviewer.username
            return true
        return false
      catch e
        return false
    enumerable: true
    configurable: true
  ###* virtual Field (Getter) returns the actionButtons
    * @name ImproveReview#userIsRequestor
    * @type {boolean}
    ###
  Object.defineProperty this::, "userIsRequestor",
    get: ->
      if this.requestor?
        return  ComponentHub.activeServer.userdata.username is this.requestor.username
      return false
    enumerable: true
    configurable: true
  ###
    * Base Class representing a ImproveReview extends [ImproveResource](ImproveResource.html)
    * Usually is the parent of one or more [reviewEntries](reviewEntry.html)
    * @constructor
    * @param {Object} rawData as returned from REST API
    ###
  constructor: (rawData) ->
    super rawData
    this.iWebType = 'ImproveReview'
    this.iWebContext = 'ImproveReview'
    this.treeColor = 'secondary'
    this.isInPlan=false
    this.isInReview=false
    this.isFinalized=false
    this.entryRecs=[]
    this.virtualParents={}
    this.virtualsInitialized = false
    this.reviewEntries=[]

  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {array}
   ###
  createToolBarActions: ->
    tba = super()
    ###   Temporary removed due IM-5839
    mybtns= [{
        hidden: false
        highlight:this.reviewStatus is 'Planning'
        icon: "o_schedule"
        disabled: true #!this.userIsRequestor
        click: () => this.isInPlan = !this.isInPlan
        tooltip: "Review State 'Planning'"
      },
      {
        hidden: false
        highlight: this.reviewStatus is 'Reviewing'
        icon: "o_manage_search"
        disabled: true #!this.userIsRequestor
        click: () => this.isInReview = !this.isInReview
        tooltip: "Review State 'Review'"
      },
      {
        hidden: false
        highlight:this.reviewStatus is 'Approved'
        icon: "o_done"
        disabled: true #!this.userIsRequestor
        click: () => this.isFinalized = !this.isFinalized
        tooltip: "Finalize and Lock"
      }]
    ###
    mybtns = []
    return tba.concat(mybtns)

  ###
    * gets all subitems of this (folderish) resource
    * mostly used by ResourceBrowser
    * @return {Array of Objects} Array, resources
    ###
  onExpand: ()->
    hasChildren = this.hasChildren # Bug in API, must temporary store this
    res = await ComponentHub.activeServer.doQuery apiGetReviewDetails this.resourceId
    rawData = res.data
    tmpReviewer = []
    if rawData.hasOwnProperty('reviewers')
      for reviewer in rawData.reviewers
        reviewer.user.reviewerId  = reviewer.id
        reviewer.user.status  = reviewer.status
        tmpReviewer.push reviewer.user
      rawData.reviewers = tmpReviewer
    rawData && Object.assign this, rawData
    this.hasChildren = hasChildren # Bug in API, must temporary store this
    @requestor = rawData.requestor
    this.reviewEntries = await this.getEntries(true)
    for entry in this.reviewEntries
      ComponentHub.FileSystemTree.onNewRecord entry
    if this.hasChildren
      ComponentHub.activeServer.fetchData  apiGetSubTreeWithMeta( this.resourceId)

  compareRevision: ( a, b ) ->
    if  a.lastModifiedOn < b.lastModifiedOn
      return 1
    if a.lastModifiedOn > b.lastModifiedOn
      return -1
    return 0


  ###
    * Loads also our relatedObjects like Metadata and revisions
    ###
  onDeepLoad: ()->
    await @getEntries()
    await @getRevisionHistory()
    await @getMetadata()
    await @ensureComments()
    return
  ###
    * load all Entries of the current Review
    * @param {boolean=true} force
    * @return {Array of Objects} Array, resources
    ###
  getEntries: (force=false) ->
    if !force and this.reviewEntries.length isnt 0
      return this.reviewEntries
    #res = await ComponentHub.activeServer.doQuery apiGetReviewReviewers(this.resourceId),false
    #if res.status is 200 and res.data.hasOwnProperty('elements')
    #  this.reviewers= res.data.elements
    # Prepare an empty cache for StepChildStepChildren
    StepChildren={}
    ComponentHub.FileSystemTree.setLoading()
    this.entryRecs=[]
    entries=[]
    # * fetch our contents
    query = apiGetReviewEntries this.resourceId
    result = await ComponentHub.activeServer.doQuery query, false
    # * create folder cache
    if result.status is 200
      folders = []
      if result.data.hasOwnProperty('elements')
        for entry in result.data.elements
          if entry.resource.nodeType is "Step"
            # for Steps we hav to look if they have ChildSteps
            # if so we create a new Property childStepChildren and
            # append it to the Step ReviewEntry
            # https://scinteco.atlassian.net/browse/IM-5497
            childStepChildren=[]
            childrenSteps = await ComponentHub.activeServer.doQuery apiResourceGetChildSteps entry.resource.parentId, entry.resource.resourceId
            if childrenSteps.status is 200
              for child in childrenSteps.data
                if child.path.startsWith this.path
                  for childEntry in child.children
                    rawChild = await ComponentHub.activeServer.doQuery apiGetResource childEntry.resourceId
                    if rawChild.status is 200
                      childElement = new ImproveResource rawChild.data
                      childElement.getRevisionHistory()
                      childStepChildren.push childElement
              if childStepChildren.length > 0
                #make an entry for this step and assign the list of childs to it
                StepChildren[entry.resource.resourceId] = childStepChildren
          resource = new ReviewEntry(entry, this )
          await resource.getRevisionHistory()
          resource['virtualParent'] = ''
          if resource.isFolderish
            # * fill the folder cache with real resourceIds of folders
            folders.push resource
          # * add everything to records
          entries.push(resource)
      # * scan records
      for resource in entries
        # https://scinteco.atlassian.net/browse/IM-5497
        if StepChildren.hasOwnProperty(resource.parentId)
          for mirrorEntry in StepChildren[resource.parentId]
            try
              # the mirrorEntry (ChildStep's resource) must be in the same path as the review itself
              if mirrorEntry.path.startsWith(resource.inReview.path) and mirrorEntry.name is  resource.name
                resource.revisionList = resource.revisionList.concat(mirrorEntry.revisionList)
                resource.revisionList.sort this.compareRevision
            catch e
              # just skip
        parent = (folder for folder in folders when folder.resourceId is resource.parentId)
        if parent.length
          resource.treeParent = parent[0].treeId
          resource.vpath = parent[0].virtualPath
        else
          # if there is no review entry folder who is the parent of this item
          # then lookup for the real folder this item lives in
          #res = await ComponentHub.activeServer.doQuery apiGetResource(resource.parentId), false
          #if res.status is 200
          #  rec = res.data
          rec = await this.getPossibleVirtualFolder resource
          if rec?
            # see if this real folder has a parent which is a review item
            reviewParent = (folder for folder in folders when folder.resourceId is rec.parentId)
            if reviewParent.length
              # so we have an item, wwhich belongs to a folder which is NOT a review item, but it's parent is one
              # so first lets instantiate it
              fileClass = getImproveClass(rec)
              vResource = new fileClass rec
              # now this thing must get a otherwise we get it twice in the tree, with same resourceId which is prohibited
              #so first fake the review specific properties
              vResource['parentId'] = reviewParent[0].treeId
              vResource['path'] = reviewParent[0].virtualPath + '/' + vResource.name
              vResource['_vpath'] = reviewParent[0].virtualPath + '/' + vResource.name
              vResource.resourceId = 'v_' + vResource.resourceId
              vResource.iWebContext = 'ImproveReview'

              #set this item to virtual
              vResource.virtual=true
              #and at least assign our resource this parent
              resource.treeParent = vResource.treeId
              resource.vpath = vResource.virtualPath
              resource['virtualParent'] = vResource.name + "/"
              folders.push(vResource)
              entries.push(vResource)


      this.entryRecs=this.treeSort(entries,"name",false)
    ComponentHub.FileSystemTree.unsetLoading()
    return entries

  getPossibleVirtualFolder: (resource) ->
    if this.virtualParents.hasOwnProperty(resource.parentId)
      return this.virtualParents[resource.parentId]
    else
      res = await ComponentHub.activeServer.doQuery apiGetResource(resource.parentId), false
      if res.status is 200
        rec = res.data
        this.virtualParents[rec.resourceId] = rec
        return rec
    return null


  ###
    * Loads also our comments if not present already
    * @return {Array} comments
    ###
  ensureComments: ()->
    if @hasOwnProperty('comments')  and @comments.length
      for entry in @comments
        entry.resourceName= this.name
      return @comments
    else
      result = await ComponentHub.activeServer.doQuery apiGetReviewComments @resourceId
      @comments = result.data
      for entry in @comments
        entry.resourceName= this.name
      return @comments




  ###
    * Creates a new review Entry inside this review
    * Overwritten. In the case a resource is copied to a review it is added as new review Entry
    * @param {improveResource} source which should be copied
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  copyFrom: (source, commitMsg) ->
    return false
    #result = await ComponentHub.activeServer.doQuery apiNewResourceEntries(@resourceId,source.resourceId)
    #return result.status is 200


  ###
    * Moves another resource into this one. This has to be folderish
    * @param {improveResource} source which should be moved
    * @param {string} [commitMsg] optional message
    * @return {boolean}
    ###
  moveFrom: (source, commitMsg) ->
    return false
    #return @copyFrom source


  ###*
    * # TreeSort
    * Sorts the table levelwise. The sorting happens only within one hierarchy level
    * called by the table widget. This method takes care of hierarchy and expanded states of the tree
    * @param {Array} rows all rows to be sorted Array of ImproveResources
    * @param {string} sortBy he column to sort after
    * @param {boolean} descending true for descending, false for ascending
    * @return {Array} the sorted array
    ###
  treeSort: (rows, sortBy, descending) ->
    this.lastSortOrder = sortBy
    this.lastSortDescending = descending
    data = [rows...]
    # We first check of the sorting should be based on another field by using the sortAlias Map

    asc = (a, b) ->
      # our plain sorting algorithm
      # change any sort logic only here
      if a[sortBy] is b[sortBy]
        return 0
      if a[sortBy] < b[sortBy]
        return -1
      return 1

    desc = (a, b) ->
      # for descending just reverse parameters
      return asc(b, a)
    # if there is any sorting requested
    if sortBy
      if descending
        depthTreeSort data, desc, 'treeId', 'treeParentId', this.treeId
      else
        depthTreeSort data, asc,  'treeId', 'treeParentId', this.treeId
    return data
