###*
  * This is called from FileSystemBrowser to instantiate automatically the correct FileClass for any new File
  * arriving through FS or RESt or whatever
  * It automatically checks all plugins for their special file extensions
  ###
registeredFileClasses = {}
for pluginName of globalThis.loadedPlugins
  registeredFileClasses =
    {globalThis.loadedPlugins[pluginName].registeredFileTypes... ,
    registeredFileClasses...}

globalThis.registeredFileClasses = registeredFileClasses

export  {registeredFileClasses}
