import {ExternalFileSystemFile} from './ExternalFile.coffee'
import ComponentHub from "core/componentsHub.coffee"
###*
 * @augments ExternalFileSystemFile
  ###
export class SettingsFile extends ExternalFileSystemFile
  ###*  Settingsfiles are managing the internal settings or workspace or plugin settings
    *
    *  This class should be overwritten by special files
    *  is instantiated
    * @constructor
    * @param {FileObject} fileObject - an FileObject like returned from FilesystemWatcher
    * @param {object} schema - A valid Schema to build up the form. Must not cover the whole structure
    ###
  constructor:  (fileObject, schema) ->
    super(fileObject)
    ###* - true becaause this is an settingsfile
      * @type {boolean}
      ###
    this.isSettingsFile = true
    unless this.model?
      this.model = null
    ###* - the Json Schema for this File
      * @type {object}
      ###
    this.schema = schema
    ###* - the Json Diagnostic Schema for this File
      * @type {object | null}
      ###
    this.diagnosticSchema = null
    this.diagnosticFileMatch = null
    this.formMode = if this.schema isnt null then true else false

  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    btns = super()
    btns.push({
      name: "SettingToggleView"
      disabled: false
      highlight:false
      hidden: if this.schema then false else true
      icon: if this.formMode then 'o_data_object' else "o_article"
      tooltip: "Toggle JSON and Form View"
      click: () => @toggle()
    })
    ###* @ts-ignore ###
    return btns

  toggle: ->
    if !this.formMode
      try
        this.model = JSON.parse(this.content)
      catch e
        alert "Invalid Json: " + e.toString()
        return
    this.formMode = !this.formMode

  update: (rawData)->
    super(rawData)
    try
      this.model = JSON.parse(this.content)
    catch e
      alert "Invalid JSON file. Could not parse json #{e}"
      this.model = null

  ###* Saves the file back to FS with its current content
    *
    * **Important** Never save back binary files !!
    * @return {void}
   ###
  save: () ->
    if @content?
      try
        this.model =  JSON.parse(@content)
      catch e
        alert "Can not save: Invalid Json: " + e.toString()
        return
      # When dealing with a workspace file, we update the workspace information in the plugin itself
      if this.model.hasOwnProperty("workSpaceType")
        globalThis.loadedPlugins[this.model.workSpaceType.toLowerCase()].onWorkSpaceSaved @path,this.model
      else if this.model.hasOwnProperty("pluginsettings")
        globalThis.loadedPlugins[this.model.pluginsettings.toLowerCase()].settings = this.model
      else if this.model.hasOwnProperty("globalAppSettings")
        ComponentHub.globalAppSettings = this.model
    super()
    return

  ###* based on the name of the resource the mime-type will be returned
    *
    * @return {string | null} String   like text/html, x-application/ms-word
    ###
  getMimeType: () ->
    return 'application/json'
