const getFiles = async  (
    dirHandle,
    recursive,
    path = dirHandle.name,
    skipDirectory
) => {
    const dirs = [];
    const files = [];
    for await (const entry of dirHandle.values()) {
        const nestedPath = `${path}/${entry.name}`;
        if (entry.kind === 'file') {
            files.push(
                entry.getFile().then((file) => {
                    file.directoryHandle = dirHandle;
                    file.fileHandle=entry;
                    file.kind = 'file';
                    return Object.defineProperty(file, 'webkitRelativePath', {
                        configurable: true,
                        enumerable: true,
                        get: () => nestedPath,
                    });
                })
            );
        } else if (
            entry.kind === 'directory' &&
            recursive &&
            (!skipDirectory || !skipDirectory(entry))
        ) {
            dirs.push(getFiles(entry, recursive, nestedPath, skipDirectory));
            
            dirs.push({directoryHandle:entry,name:entry.name,kind:entry.kind,webkitRelativePath:nestedPath})
        }
    }
    return [...(await Promise.all(dirs)).flat(), ...(await Promise.all(files))];
};

export {getFiles}