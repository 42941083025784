import ComponentHub from "./componentsHub.coffee"
import { LocalStorage, SessionStorage } from 'quasar'



export class LSSettingsFileObject
  Object.defineProperty LSSettingsFileObject::, "tabPinned",
    get: ->
      return this._tabPinned
    enumerable: true
    configurable: true
    set: (val) ->
      this._tabPinned = val

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name ImproveResource#currentEditor
    * @type {object|null} currentEditor
    ###
  Object.defineProperty this::, "currentEditor",
    get: -> this._editor
    enumerable: true
    configurable: true
    set: (val) ->
      this._editor = val

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name ImproveResource#currentEditorIsMonaco
    * @type {boolean} currentEditorIsMonaco
    ###
  Object.defineProperty this::, "currentEditorIsMonaco",
    get: ->
      if this._editor?
        if this._editor.editorType is 'monaco'
          return true
      return false
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) fakes an inode number
    * LSSettingObjectsalso provides an array of contextual action buttons
    * @name LSSettingsFileObject#actionButtons
    * @type {array}
    ###
  Object.defineProperty LSSettingsFileObject::, "actionButtons",
    get: ->
      return this.createToolBarActions()
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) fakes an inode number
    * LSSettingObjects (LocalStorage-SettingsObject) are not real files with an ino nor resources with an resourceID
    * So we have to fake the ino property with a fake value
    * @name LSSettingsFileObject#ino
    * @type {string} ino
    ###
  Object.defineProperty LSSettingsFileObject::, "ino",
    get: ->
      return this.path + "pseudoIno"
    enumerable: true
    configurable: true

  ###*  Settingsfiles are managing the internal settings or workspace or plugin settings
    *  This represents a virtual SettingsfFile which is stored in local Storage
    *  This class should be overwritten by special files
    *  is instantiated
    * @constructor
    * @param {string} path - a virtual path in LS
    * @param {object} model - If it is new, an empty template matching the content must be provided
    * @param {object} schema - A schema for the UI
    ###
  constructor:  (path, model, schema) ->
    ###* - true because this is an settingsfile
      * @type {boolean}
      ###
    this.isSettingsFile = true
    ###* - the model
      * @type {object}
      ###
    this.model = model
    ###* - the UI Schema
      * @type {object}
      ###
    this.schema = schema
    ###* - path
      * @type {string}
      ###
    this.path = path
    ###* - the Json Diagnostic Schema for this File
      * @type {object | null}
      ###
    this.diagnosticSchema = null
    this.diagnosticFileMatch = null
    this.formMode = if this.schema isnt null then true else false
    this._tabPinned = true
    this.content=""
    this.displayName=""
    this.oldName=""
    if this.model? and this.model.hasOwnProperty('name')
      this.oldName = this.model.name
    ###* - The editor we are currretnly loaded in needed for compatibilty with FileSystemFile and ImproveResource
      * @type {object|null}
      ###
    this._editor = null


  ###* Returns our actions
    * @description This has to ba a function which is called by a property getter, because they have to be recalculated
    * Every time the container which handles this file is focussed
    * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    btns =[{
      name: "SettingToggleView"
      highlight:false
      disabled: false
      hidden: if this.schema then false else true
      icon: if this.formMode then 'o_data_object' else "o_article"
      tooltip: "Toggle JSON and Form View"
      click: () => @toggle()
    }]
    ###* @ts-ignore ###
    return btns

  toggle: ->
    this.formMode = !this.formMode

  ###* Monaco cant wotrk with promises, so this is called at mount in editorng
   * @return {string | null}
  ###
  getInitContent: ->
    return this.getContent()

  getContent: ->
    return JSON.stringify(this.model,null,4)

  update: (rawData)->
    try
      this.model = JSON.parse(this.content)
    catch e
      alert "Invalid JSON file. Could not parse json #{e}"
      this.model = null


  ###* Saves the file back to FS with its current content
    *
    * **Important** Never save back binary files !!
    * @return {void}
    ###
  save: () ->
    if @content?
      try
        this.model =  JSON.parse(@content)
      catch err
        ###* @ts-ignore###
        alert "Can not save: Invalid Json: " + err.toString()
        return
      # When dealing with a workspace file, we update the workspace information in the plugin itself
      if this.model.hasOwnProperty("workSpaceType")
        try
          this.model.workspaceUrl="#{window.location.origin}#{window.location.pathname}?plugin=#{this.model.workSpaceType}&wksType=#{this.model.workSpaceType}&workspacename=#{this.model.name}&host=#{this.model.improveHost}&api=#{this.model.improveApi}&repo=#{this.model.improveRepo}&root=#{this.model.rootPath}"
        catch e
          console.warn "Workspace Url not saved!"
        password = this.model.ssPassword
        if this.model.name isnt this.oldName
          await ComponentHub.dbSetWorkspaceAndRename this.path.toLowerCase(),this.oldName.toLowerCase(), this.model.name.toLowerCase(), this.model
        else
          await ComponentHub.dbSetWorkspace this.path.toLowerCase(), this.model.name.toLowerCase(), this.model
        await ComponentHub.dbLoginAllWorkspaces this.model.improveHost, this.model.username, password
        globalThis.loadedPlugins[this.model.workSpaceType.toLowerCase()].onWorkSpaceSaved this.model
      else if this.model.hasOwnProperty("pluginsettings")
        globalThis.loadedPlugins[this.model.pluginsettings.toLowerCase()].settings = this.model
      else if this.model.hasOwnProperty("globalAppSettings")
        await ComponentHub.saveGlobalSettings this.model
    return

  ###* based on the name of the resource the mime-type will be returned
    *
    * @return {string | null} String   like text/html, x-application/ms-word
    ###
  getMimeType: () ->
    return 'application/json'

